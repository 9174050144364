import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaSegmento: 'configuration/sidebard/segment/available',
    listaTag: 'configuration/sidebard/tag/available',
    listaListas: 'configuration/sidebard/contact_groups/available',
    save: 'configuration/sidebard/apf/add'
}

export async function ListaSegmento() {
    try {
        const response = await postRequest(apiUrls.listaSegmento, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaTag() {
    try {
        const response = await postRequest(apiUrls.listaTag, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaListas() {
    try {
        const response = await postRequest(apiUrls.listaListas, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            activatorId: params.activatorId,
            nodeId: params.nodeId,
            isRealTime: params.isRealTime,
            typeContact: params.typeContact,
            contactId: params.contactId
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "theme": "auto",
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}