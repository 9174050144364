<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div class="workflow-custom-node" @click="nodeInfo">
    <div class="workflow-custom-node-icon" :class="props.data.icon">
      <component :is="iconMapper[props.data.icon] || IconClipboard" size="24" color="#FFF" />
    </div>
    <div class="workflow-custom-node-text">
      <h5>{{ props.data.title }}</h5>
      <p>{{ props.data.description }}</p>
      <!-- DELETE NODE -->
      <div class="workflow-custom-node-delete position-relative">
        <button @click="toggleModal">
          <span>Eliminar</span>
          <IconTrash size="10" />
        </button>
        <ModalDelete
          :active="modalActive"
          :style="{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }"
          :customNodeType="true"
          @close="closeModal"
          @delete="continueDelete"
        />
      </div>
      <!-- ADD ACTION -->
      <div v-if="!veirfyInvalidAddOptionIcon(props.data.icon)" class="workflow-custom-node-add">
        <button :disabled="props.data.availableAddOption" @click="optionsForNode()">
          <IconPlus size="12" />
        </button>
      </div>
    </div>
    <div v-if="optionsForNodeActive" class="workflow-custom-node-options">
      <h6>Canales</h6>
      <button v-for="(dataCanales, i) in actions.Canales" :key="i" @click="addOptionForNode(dataCanales, props.data.id, 'canales')">{{ dataCanales.name }}</button>
      <h6>Funciones</h6>
      <button v-for="(dataFunciones, i) in actions.Funciones" :key="i" @click="addOptionForNode(dataFunciones, props.data.id, 'funciones')">{{ dataFunciones.name }}</button>
    </div>
  </div>
  <Handle id="point" type="source" :position="Position.Bottom" />
  <Handle v-if="props.data.type === 'optionNode'" id="point" type="target" :position="Position.Top" />
</template>
  
<script setup>
import { iconMapper } from './iconMapper.js';
import { IconClipboard, IconPlus, IconTrash } from '@tabler/icons-vue';
import { Handle, Position } from '@vue-flow/core'
import { defineProps, defineEmits, ref  } from 'vue';
import { ActionList } from '../services/apiServices.js';
import ModalDelete from '../ModalDelete.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const namesForInvalidAddOptionIcon = ['MensajeInbound', 'CambioPerfil', 'EventoTiempoReal', 'FechaHora']

function veirfyInvalidAddOptionIcon(name) {
  return namesForInvalidAddOptionIcon.includes(name);
}

let optionsForNodeActive = ref(false)

const emit = defineEmits(['addOptionForNode', 'deleteCustomNode', 'nodeInfo']);

function addOptionForNode(data, id, nodeType){
  optionsForNodeActive.value = false
  emit('addOptionForNode', [data, id, nodeType]);
}

function deleteCustomNode(id) {
  emit('deleteCustomNode', [id, props.data.parent]);
}

function nodeInfo(){
  emit('nodeInfo', props.data)
}

// APIs
let actions = ref([])

async function optionsForNode(){
  if(actions.value.length === 0){
    actions.value = await ActionList()
  }
  optionsForNodeActive.value = !optionsForNodeActive.value
}

// MODAL DELETE
let modalActive = ref(false)
let modalPosition = ref({ top: 0, left: 0 })

function closeModal(){
  modalActive.value = false
}
function continueDelete(){
  deleteCustomNode(props.data.id)
  modalActive.value = false
}
function toggleModal(event){
  modalActive.value ? modalActive.value = false : modalActive.value = true
  if (modalActive.value) {
      const positionRelative = event.currentTarget;
      const rect = positionRelative.getBoundingClientRect();
      modalPosition = {
      top: rect.top,
      left: rect.left,
      };
  }
}
</script>
  