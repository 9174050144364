import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaSenders: 'configuration/sidebard/sender/available',
    listaTemplates: 'action/configuration/sidebard/email/template',
    save: 'action/configuration/sidebard/email/add',
}

export async function ListaSenders() {
    const requestData = {
        request: JSON.stringify({
            channelId: 3,
        })
    };
    try {
        const response = await postRequest(apiUrls.listaSenders, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaTemplates() {
    try {
        const response = await postRequest(apiUrls.listaTemplates, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            actionId: params.actionId,
            nodeId: params.nodeId,
            actionType: params.actionType,
            text: params.text,
            subject: params.subject,
            fromEmail: params.fromEmail,
            templateId: params.templateId,
            startTime: params.startTime,
            endTime: params.endTime,
            daysOfWeek: params.daysOfWeek,
            isShortUrl: params.isShortUrl,
            timeUnit: params.timeUnit,
            messageInterval: params.messageInterval,
            useTimeConfiguration: params.useTimeConfiguration || false,
            clickPerContact: params.clickPerContact,
            doNotContact: params.doNotContact,
            messageType: params.messageType
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "theme": "auto",
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}