import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaTag: 'action/configuration/sidebard/tags/available',
    save: 'action/configuration/sidebard/tag/add',
}

export async function ListaTag() {
    try {
        const response = await postRequest(apiUrls.listaTag, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            actionId: params.actionId,
            nodeId: params.nodeId,
            actionType: params.actionType,
            addTag: params.addTag === 2 ? true : false,
            tagName: params.tagName,
            selectedTag: params.selectedTag,
            nodeAttributeId: 0
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "theme": "auto",
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}