import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://automata2.celcom.cl/staging_flex/rest/workflow/',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 5000,
});

export const getRequest = (url, params = {}) => {
  return axiosInstance.get(url, { params });
};

export const postRequest = (url, data = {}) => {
  const formattedData = new URLSearchParams();
  for (const key in data) {
    formattedData.append(key, data[key]);
  }
  return axiosInstance.post(url, formattedData);
};

export const putRequest = (url, data = {}) => {
  const formattedData = new URLSearchParams();
  for (const key in data) {
    formattedData.append(key, data[key]);
  }
  return axiosInstance.put(url, formattedData);
};

export const deleteRequest = (url, params = {}) => {
  return axiosInstance.delete(url, { params });
};
