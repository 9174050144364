<template>
    <div class="workflow">
        <transition name="WorkflowNewActivatorFade">
            <LoaderComponent v-if="loaderActive"/>
        </transition>
        <div v-if="!newWorkflowActive" class="workflow-panel">
            <div class="workflow-management-title">
                <h1>Gestión de Workflows</h1>
                <button @click="newWorkflowActive = true">
                    <IconPlus size="12" stroke-width="4"/>
                    <span>Nuevo Workflow</span>
                </button>
            </div>
            <div class="workflow-management-filters">
                <div>
                    <p class="workflow-management-filters-label">Nombre</p>
                    <input v-model="filterWorkflowName" type="text" class="form-control" placeholder="Escribir" @keyup.enter="filterWorkflowList">
                </div>
                <div>
                    <p class="workflow-management-filters-label">Estado</p>
                    <select
                        :id="'filterWorkflowStateId'"
                        v-model="filterWorkflowState"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option :value="0">Todos</option>
                        <option v-for="(data, i) in stateListData" :key="i" :value="data.id">{{ data.name }}</option>
                    </select>
                </div>
                <div>
                    <p class="workflow-management-filters-label">Creado Por</p>
                    <select
                        :id="'filterWorkflowUserCreateId'"
                        v-model="filterWorkflowUserCreate"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option :value="0">Todos</option>
                        <option v-for="(data, i) in customerListData" :key="i" :value="data.customerId">{{ data.name }}</option>
                    </select>
                </div>
                <div>
                    <p class="workflow-management-filters-label">Fecha de Creación</p>
                    <InputDate @changeDate="changeDate" @clearDate="clearDate"/>
                </div>
            </div>
            <div class="workflow-table-scroll">
                <div class="table-responsive">
                    <table class="table align-items-center mb-0 workflow-table">
                        <thead>
                            <tr>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Nombre
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Enviados
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Costo
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Estado
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Creado Por
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Fecha de Creación
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, i) in workflowListData.data" :key="i">
                                <td class="align-middle text-center text-sm">
                                    <h6 class="mb-0 text-xs">{{ data.workflowName }}</h6>
                                </td>
                                <td class="align-middle text-center text-sm">
                                    <p class="mb-0 text-xs">{{ data.numberOfSent }}</p>
                                </td>
                                <td class="align-middle text-center text-sm">
                                    <p class="mb-0 text-xs">{{ data.cost }}</p>
                                </td>
                                <td class="align-middle text-center text-sm">
                                    <p class="mb-0 text-xs">{{ data.state }}</p>
                                </td>
                                <td class="align-middle text-center text-sm">
                                    <p class="mb-0 text-xs">{{ data.createUser }}</p>
                                </td>
                                <td class="align-middle text-center text-sm">
                                    <p class="mb-0 text-xs">{{ data.createDate }}</p>
                                </td>
                                <td class="align-middle text-center text-sm">
                                    <WorkflowActionsIcons :workflowId="data.workflowId" @deleteWorkflow="deleteWorkflow"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="tableNoData" class="workflow-table-noData">
                <IconMoodSad size="32" />
                <span>No se encontraron datos para mostrar. Cambie de filtro o comience a crear su primer workflow!</span>
            </div>
            <WorkflowTablePagination v-if="Object.keys(workflowListData).length > 0" :total-items="workflowListData.totalRecords" :items-per-page="filterWorkflowRecordAmount" @changePage="changePage" :updateRecordAmmount="updateRecordAmmount"/>
            <div class="workflow-table-record-amount">
                <span>Registros por página</span>
                <select
                    :id="'filterWorkflowRecordAmountId'"
                    v-model="filterWorkflowRecordAmount"
                    class="multisteps-form__select form-control form-select"
                >
                    <option :value="5">5</option>
                    <option :value="10">10</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                </select>
            </div>
        </div>
        <div v-if="newWorkflowActive" class="workflow-panel">
            <div class="workflow-management-title">
                <h1>Nuevo Workflow</h1>
                <button @click="newWorkflowActive = false; store.goToTemplates = false">
                    <IconArrowLeft size="12" stroke-width="4"/>
                    <span>Volver</span>
                </button>
            </div>
            <WorkflowNewWorkflow/>
        </div>
    </div>
</template>

<script>
import { IconArrowLeft, IconMoodSad, IconPlus } from '@tabler/icons-vue';
import WorkflowTablePagination from './WorkflowTablePagination.vue';
import WorkflowNewWorkflow from './WorkflowNewWorkflow.vue';
import { WorkflowList, CustomerList, CustomerId, StateList } from '../services/apiServices';
import LoaderComponent from '../LoaderComponent.vue';
import WorkflowActionsIcons from './WorkflowActionsIcons.vue';
import InputDate from './WorkflowInputDate.vue';
import { useWorkflowStore } from '../store/workflowStore';

export default{
    name: 'WorkflowList',
    components: {
        IconPlus,
        IconArrowLeft,
        WorkflowTablePagination,
        WorkflowNewWorkflow,
        LoaderComponent,
        WorkflowActionsIcons,
        InputDate,
        IconMoodSad
    },
    data: () => ({
        loaderActive: true,
        workflowListData: [],
        customerListData: [],
        stateListData: [],
        customerId: '',
        currentPage: 1,
        filterWorkflowName: '',
        filterWorkflowState: 0,
        filterWorkflowUserCreate: 0,
        filterWorkflowCreateDate: '',
        filterWorkflowRecordAmount: 5,
        newWorkflowActive: false,
        updateRecordAmmount: false,
        store: useWorkflowStore(),
        tableNoData: false
    }),
    computed: {
        goToTemplates(){
            return this.store.goToTemplates
        }
    },
    watch: {
        currentPage(){
            this.filterWorkflowList()
        },
        filterWorkflowState(){
            this.filterWorkflowList()
        },
        filterWorkflowUserCreate(){
            this.filterWorkflowList()
        },
        filterWorkflowCreateDate(){
            this.filterWorkflowList()
        },
        filterWorkflowRecordAmount(){
            this.updateRecordAmmount = !this.updateRecordAmmount
            this.filterWorkflowList()
        }
    },
    async mounted() {
        const store = useWorkflowStore()
        this.customerId = await CustomerId()
        this.initialApiRequest()
        store.customerId = this.customerId
        this.newWorkflowActive = this.goToTemplates
    },
    methods: {
        createDate(data) {
            this.filterWorkflowCreateDate = data
        },
        async initialApiRequest (){
            this.loaderActive = true
            this.workflowListData = await WorkflowList({
                customerId: this.customerId,
            })
            this.workflowListData.data.length > 0 ? this.tableNoData = false : this.tableNoData = true
            this.customerListData = await CustomerList()
            this.stateListData = await StateList()
            this.loaderActive = false
        },
        async filterWorkflowList(){
            const params = {
                customerId: this.customerId,
                page: this.currentPage,
                size: this.filterWorkflowRecordAmount,
                filterWorkflowName: this.filterWorkflowName,
                filterWorkflowState: this.filterWorkflowState,
                filterWorkflowUserCreate: this.filterWorkflowUserCreate,
                filterWorkflowCreateDate: this.filterWorkflowCreateDate
            }
            this.loaderActive = true
            this.workflowListData = await WorkflowList(params)
            this.loaderActive = false
        },
        changePage(data){
            this.currentPage = data
        },
        changeDate(data){
            this.filterWorkflowCreateDate = data
        },
        clearDate(data){
            this.filterWorkflowCreateDate = data
        },
        deleteWorkflow(){
            this.updateRecordAmmount = !this.updateRecordAmmount
            this.initialApiRequest()
        }
    },
}
</script>