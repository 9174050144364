import { defineStore } from 'pinia';
import { WorkflowConfiguration } from '../services/apiServices';

export const useWorkflowStore = defineStore('workflowStore', {
  state: () => ({
    customerId: 0,
    workflowId: 0,
    workflowConfigurationData: null,
    typeOfWorkflowPanel: '',
    lastAttributeCreate: null,
    workflowIsCreate: true,
    workflowPanelNotSaved: false,
    workflowSidebarNotSaved: false,
    goToTemplates: false,
    changeHistory: [],
    loaderWorkflowPanel: false,
    sidebarMessageNotSaved: false,
    sidebarActive: false
  }),
  actions: {
    async setWorkflowConfigurationData(data){
        const params = {
            workflowId: data
        }
        this.workflowConfigurationData = await WorkflowConfiguration(params)
    },
    setLastAttributeCreate(nuevoValor) {
      this.lastAttributeCreate = nuevoValor
    }
  },
  getters: {}
});