<!-- eslint-disable vue/attributes-order -->
<template>
    <div class="container-fluid my-3 EventoTiempoReal-container">
        <div v-for="(data, i) in forms" :key="i" class="EventoTiempoReal-condition mt-3 px-3 pt-3 pb-3">
            <div class="row">
                <div class="col-6">
                    <p class="workflow-management-filters-label">Atributo</p>
                    <select
                        v-model="data.attribute"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option v-for="(atributo, i2) in ListaAtributosData" :key="i2" :value="atributo.id">{{ atributo.name }}</option>
                    </select>
                </div>
            </div>
            <div v-for="(conditionItem, index) in data.conditionsArray" :key="index" class="EventoTiempoReal-condition-child mt-3">
                <div v-if="index > 0" class="row">
                    <div class="col-4">
                        <p class="workflow-management-filters-label">Tipo de condición</p>
                        <select
                            v-model="conditionItem.typeOfCondition"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option value="AND">Y</option>
                            <option value="OR">O</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <p class="workflow-management-filters-label">Condiciones</p>
                        <select
                            v-model="conditionItem.conditionalOne"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option v-for="(condicion, i2) in ListaCondicionesData" :key="i2" :value="condicion.id">{{ condicion.name }}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <p class="workflow-management-filters-label">Condición</p>
                        <input v-model="conditionItem.countConditionalOne" type="text" class="form-control" placeholder="Escribir">
                    </div>
                </div>
                <div class="row justify-content-between mt-3">
                    <div class="col-auto">
                        <button type="button" class="btn btn-warning m-0" @click="addConditionChild(i)">
                            <div class="d-flex align-items-center gap-1">
                                <IconTablePlus color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                                <span>Y / O</span>
                            </div>
                        </button>
                    </div>
                    <div v-if="index > 0" class="col-auto">
                        <button type="button" class="btn btn-danger mb-0" @click="removeConditionChild(index, i)">
                            <div class="d-flex align-items-center gap-1">
                                <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                                <!-- <span>Eliminar</span> -->
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="i > 0" class="row justify-content-end">
                <div class="col-auto">
                    <button type="button" class="btn btn-danger mb-0 mt-3" @click="removeForm(data.nodeAttributeId)">
                        <div class="d-flex align-items-center gap-1">
                            <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Eliminar</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div>
            <div class="row justify-content-between my-4">
                <div class="col-auto">
                    <button type="button" class="btn btn-info" @click="newForm">
                        <div class="d-flex align-items-center gap-1">
                            <IconPlaylistAdd color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Agregar condición</span>
                        </div>
                    </button>
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-primary" @click="save">
                        <div class="d-flex align-items-center gap-1">
                            <IconDeviceFloppy color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Grabar configuración</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconPlaylistAdd, IconDeviceFloppy, IconTrash, IconTablePlus } from '@tabler/icons-vue';
import { ListaAtributos, ListaCondiciones, SaveConfiguration } from './apiServices';
import { useWorkflowStore } from '../../../store/workflowStore';

export default{
    name: 'CambioPerfil',
    components: {
        IconPlaylistAdd,
        IconDeviceFloppy,
        IconTrash,
        IconTablePlus
    },
    props: {
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['createStructureForAttribute', 'deleteStructureForAttribute', 'attributesAddNotSaved'],
    data: () => ({
        channels: null,
        ListaAtributosData: [],
        ListaCondicionesData: [],
        forms: [],
        attributesAddNotSaved: [],
        firstMounted: 0,
        store: useWorkflowStore()
    }),
    watch: {
        dataForSidebarConfiguration: {
            async handler(newVal){
                if (Object.entries(newVal).length !== 0) {
                    this.forms = newVal.conditions
                }
            },
            deep: true
        },
        forms: {
            handler(){
                if(this.firstMounted >= 1){
                    this.store.sidebarMessageNotSaved = true
                }
            },
            deep: true
        }
    },
    mounted(){
        this.ListaAtributosData.length === 0 ? this.ObtainListaAtributos() : ''
        this.ListaCondicionesData.length === 0 ? this.ObtainListaCondiciones() : ''
        if(this.firstMounted > 0){
            this.newForm()
        }
    },
    unmounted(){
        if(this.store.sidebarMessageNotSaved){
            this.$emit('attributesAddNotSaved', this.attributesAddNotSaved)
        }
    },
    methods: {
        newForm(){
            this.createStructureForAttribute()
            this.forms.push({
                attribute: null,
                nodeAttributeId: this.store.lastAttributeCreate,
                conditionsArray: [
                    {
                        typeOfCondition: null,
                        conditionalOne: null,
                        countConditionalOne: '',
                    }
                ]
            })
            this.attributesAddNotSaved.push(this.store.lastAttributeCreate)
            this.firstMounted += 1
        },
        removeForm(id){
            this.forms = this.forms.filter(objeto => objeto.nodeAttributeId !== id);
            this.deleteStructureForAttribute(id)
        },
        addConditionChild(index){
            this.forms[index].conditionsArray.push({
                typeOfCondition: null,
                conditionalOne: null,
                countConditionalOne: '',
            })
        },
        removeConditionChild(index, i){
            this.forms[i].conditionsArray.splice(index, 1);
        },
        async ObtainListaAtributos(){
            this.ListaAtributosData = await ListaAtributos()
        },
        async ObtainListaCondiciones(){
            this.ListaCondicionesData = await ListaCondiciones()
        },
        async save(){
            const params = {
                activatorId: this.idNodeType,
                nodeId: this.nodeId,
                conditions: this.forms
            }
            await SaveConfiguration(params)
            this.store.sidebarMessageNotSaved = false
        },
        createStructureForAttribute(){
            this.$emit('createStructureForAttribute', '')
        },
        deleteStructureForAttribute(id){
            this.$emit('deleteStructureForAttribute', id)
        }
    }
}
</script>

<style scoped>
.EventoTiempoReal-container{
    max-height: calc(100% - 100px);
    overflow-y: auto;
}

.EventoTiempoReal-container::-webkit-scrollbar {
    width: 0px;
}

.EventoTiempoReal-condition{
    background-color: #eeeeee;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
}

.EventoTiempoReal-condition-child{
    background-color: #dddcdc;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    padding: 12px 8px;
}
</style>