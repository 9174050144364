import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaBuscarEn: 'configuration/sidebard/fh/dropdown/1/available',
    listaCondicion: 'configuration/sidebard/fh/dropdown/2/available',
    listaDiasHoras: 'configuration/sidebard/fh/dropdown/3/available',
    save: 'configuration/sidebard/fh/add'
}

export async function ListaBuscarEn() {
    try {
        const response = await postRequest(apiUrls.listaBuscarEn, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaCondicion() {
    try {
        const response = await postRequest(apiUrls.listaCondicion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaDiasHoras() {
    try {
        const response = await postRequest(apiUrls.listaDiasHoras, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            activatorId: params.activatorId,
            nodeId: params.nodeId,
            dropdown1Id:  params.dropdown1Id,
            dropdown2Id: params.dropdown2Id,
            dropdown3Id: params.dropdown3Id,
            days: params.days
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "theme": "auto",
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}