import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaTipoEvento: 'configuration/sidebard/etr/dropdown/1/available',
    listaEstadoEjecucion: 'configuration/sidebard/etr/dropdown/2/available',
    listaCondicionales: 'configuration/sidebard/etr/dropdown/3/available',
    listaPeriodoEjecucion: 'configuration/sidebard/etr/dropdown/4/available',
    save: 'configuration/sidebard/etr/add'
}

export async function ListaTipoEvento() {
    try {
        const response = await postRequest(apiUrls.listaTipoEvento, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaEstadoEjecucion() {
    try {
        const response = await postRequest(apiUrls.listaEstadoEjecucion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaCondicionales() {
    try {
        const response = await postRequest(apiUrls.listaCondicionales, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaPeriodoEjecucion() {
    try {
        const response = await postRequest(apiUrls.listaPeriodoEjecucion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            activatorId: params.activatorId,
            nodeId: params.nodeId,
            conditions: params.conditions,
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "theme": "auto",
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}