<template>
    <div class="loader">
        <div class="justify-content-center jimu-primary-loading"></div>
    </div>
</template>

<script>
export default{
    name: 'LoaderComponent'
}
</script>

<style scoped>
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0,0,0,.4);
  backdrop-filter: blur(5px);
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: '';
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: #0235A0;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 #0235A0;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #0235A0;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 #0235A0;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #0235A0;
    height: 40px;
  }
}
</style>