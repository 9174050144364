<!-- eslint-disable vue/attributes-order -->
<template>
  <div class="container-fluid my-3 EventoTiempoReal-container">
      <div v-for="(form, i) in forms" :key="i" class="EventoTiempoReal-condition mt-3 px-3 pt-3 pb-3">
        <h5>Segmento</h5>
        <div class="EventoTiempoReal-condition-child mt-3">
          <h6>Atributo</h6>
          <div class="row">
            <div class="col-6">
              <p class="workflow-management-filters-label">Evento</p>
                <select
                  v-model="form.attribute.attribute"
                  class="multisteps-form__select form-control form-select"
                >
                  <option v-for="item in atributoList" :key="item.headerId" :value="item.headerId">{{ item.headerName }}</option>
                </select>
            </div>
            <div class="col-6">
              <p class="workflow-management-filters-label">Condicion</p>
                <select
                  v-model="form.attribute.condition"
                  class="multisteps-form__select form-control form-select"
                >
                  <option v-for="item in atributoCondicionList" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
            <div class="col-12 mt-3">
              <p class="workflow-management-filters-label">Texto para Analizar</p>
              <input v-model="form.attribute.description" class="form-control"/>
            </div>
          </div>
        </div>
        <div class="EventoTiempoReal-condition-child mt-3">
          <h6>Comportamiento</h6>
          <div class="row">
            <div class="col-6">
              <p class="workflow-management-filters-label">Evento</p>
                <select
                  v-model="form.behavior.event"
                  class="multisteps-form__select form-control form-select"
                >
                  <option v-for="item in comportamientoEventoList" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
            <div class="col-6">
              <p class="workflow-management-filters-label">Condicion</p>
                <select
                  v-model="form.behavior.optionEvent"
                  class="multisteps-form__select form-control form-select"
                >
                  <option v-for="item in comportamientoCondicionList" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
            <div class="col-6 mt-3">
              <p class="workflow-management-filters-label">Comparación</p>
                <select
                  v-model="form.behavior.condition"
                  class="multisteps-form__select form-control form-select"
                >
                  <option v-for="item in comportamientoComparacionList" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
            <div class="col-6 mt-3">
              <p class="workflow-management-filters-label">Cantidad</p>
              <input v-model="form.behavior.descriptionCondition" class="form-control" type="number"/>
            </div>
            <div class="col-6 mt-3">
              <p class="workflow-management-filters-label">Rango de Tiempo</p>
                <select
                  v-model="form.behavior.time"
                  class="multisteps-form__select form-control form-select"
                >
                  <option v-for="item in comportamientoRangoTiempoList" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mt-3">
              <p class="workflow-management-filters-label">Cantidad</p>
              <input v-model="form.behavior.descriptionTime" class="form-control" type="number"/>
            </div>
            <div class="col-6 mt-3">
              <p class="workflow-management-filters-label">Unidad de Tiempo</p>
                <select
                  v-model="form.behavior.timeUnit"
                  class="multisteps-form__select form-control form-select"
                >
                  <option v-for="item in comportamientoUnidadTiempoList" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
          </div>
        </div>
          <div v-if="i > 0" class="row justify-content-end">
              <div class="col-auto">
                  <button type="button" class="btn btn-danger mb-0 mt-3" @click="removeForm(form.nodeAttributeId)">
                      <div class="d-flex align-items-center gap-1">
                          <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                          <span>Eliminar</span>
                      </div>
                  </button>
              </div>
          </div>
      </div>
      <div>
          <div class="row justify-content-between mt-4">
              <div class="col-auto">
                  <button type="button" class="btn btn-info" @click="newForm">
                      <div class="d-flex align-items-center gap-1">
                          <IconPlaylistAdd color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                          <span>Agregar condición</span>
                      </div>
                  </button>
              </div>
              <div class="col-auto">
                  <button type="button" class="btn btn-primary" @click="save">
                      <div class="d-flex align-items-center gap-1">
                          <IconDeviceFloppy color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                          <span>Grabar configuración</span>
                      </div>
                  </button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { IconPlaylistAdd, IconDeviceFloppy, IconTrash } from '@tabler/icons-vue';
import { ListaAtributo,
  ListaAtributoCondicion,
  ListaComportamientoEvento,
  ListaComportamientoCondicion,
  ListaComportamientoComparacion,
  ListaComportamientoRangoTiempo,
  ListaComportamientoUnidadTiempo,
  SaveConfiguration } from './apiServices';
import { useWorkflowStore } from '../../../store/workflowStore';

export default{
  name: 'EventoTiempoReal',
  components: {
      IconPlaylistAdd,
      IconDeviceFloppy,
      IconTrash
  },
  props: {
      nodeId: {
          type: String,
          required: true
      },
      idNodeType: {
          type: Number,
          required: true
      },
      actionType: {
        type: String,
        required: true
      },
      dataForSidebarConfiguration: {
          type: Object,
          default: () => ({})
      }
  },
  emits: ['createStructureForAttribute', 'deleteStructureForAttribute', 'attributesAddNotSaved'],
  data: () => ({
      store: useWorkflowStore(),
      forms: [],
      attributesAddNotSaved: [],
      atributoList: [],
      atributoCondicionList: [],
      comportamientoEventoList: [],
      comportamientoCondicionList: [],
      comportamientoComparacionList: [],
      comportamientoRangoTiempoList: [],
      comportamientoUnidadTiempoList: [],
      firstMounted: 0
  }),
  watch: {
      dataForSidebarConfiguration: {
          async handler(newVal){
              if (Object.entries(newVal).length !== 0) {
                  this.forms = newVal.segments
              }
          },
          deep: true
      },
      forms: {
          handler(){
              if(this.firstMounted >= 1){
                  this.store.sidebarMessageNotSaved = true
              }
          },
          deep: true
      }
  },
  async mounted(){
    this.atributoList = await ListaAtributo()
    this.atributoCondicionList = await ListaAtributoCondicion()
    this.comportamientoEventoList = await ListaComportamientoEvento()
    this.comportamientoCondicionList = await ListaComportamientoCondicion()
    this.comportamientoComparacionList = await ListaComportamientoComparacion()
    this.comportamientoRangoTiempoList = await ListaComportamientoRangoTiempo()
    this.comportamientoUnidadTiempoList = await ListaComportamientoUnidadTiempo()
      if(this.firstMounted > 0){
          this.newForm()
      }
  },
  unmounted(){
      if(this.store.sidebarMessageNotSaved){
          this.$emit('attributesAddNotSaved', this.attributesAddNotSaved)
      }
  },
  methods: {
      newForm(){
          this.createStructureForAttribute()
          this.forms.push({
            nodeAttributeId: this.store.lastAttributeCreate,
            attribute: {
              attribute: null,
              condition: null,
              description: null
            },
            behavior: {
              event: null,
              optionEvent: null,
              condition: null,
              descriptionCondition: null,
              time: null,
              descriptionTime: '',
              timeUnit: null
            }
          })
          this.attributesAddNotSaved.push(this.store.lastAttributeCreate)
          this.firstMounted += 1
      },
      removeForm(id){
          this.forms = this.forms.filter(objeto => objeto.nodeAttributeId !== id);
          this.deleteStructureForAttribute(id)
      },
      async save(){
          const params = {
              nodeId: this.nodeId,
              actionId: this.idNodeType,
              actionType: this.actionType,
              segments: this.forms
          }
          await SaveConfiguration(params)
          this.store.sidebarMessageNotSaved = false
      },
      createStructureForAttribute(){
          this.$emit('createStructureForAttribute', '')
      },
      deleteStructureForAttribute(id){
          this.$emit('deleteStructureForAttribute', id)
      }
  }
}
</script>

<style scoped>
.EventoTiempoReal-container{
  max-height: calc(100% - 100px);
  padding-bottom: 32px;
  overflow-y: auto;
}

.EventoTiempoReal-container::-webkit-scrollbar {
  width: 0px;
}

.EventoTiempoReal-condition{
  background-color: #eeeeee;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0,0,0,.2);
}

.EventoTiempoReal-condition-child{
  background-color: #dddcdc;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0,0,0,.2);
  padding: 12px 8px;
}
</style>