<template>
    <div class="workflow-new-workflow">
        <transition name="WorkflowNewActivatorFade">
            <LoaderComponent v-if="loaderActive"/>
        </transition>
        <div class="workflow-new-workflow-filter">
            <button :class="{'active': generalActive}" @click="generalTemplate">General</button>
            <button :class="{'active': ownActive}" @click="customerTemplate">Mis Templates</button>
        </div>
        <div class="workflow-new-workflow-grid">
            <div class="workflow-new-workflow-grid-item">
                <div>
                    <h6>Lienzo en Blanco</h6>
                    <div class="workflow-new-workflow-grid-item-buttons">
                        <button class="go" @click="newWorkflowBlank">
                            <RouterLink to="/WorkflowPanel">
                                <IconArrowRight size="16" />
                            </RouterLink>
                        </button>
                    </div>
                </div>
            </div>
            <div v-for="(data, i) in workflowTemplateListData" :key="i" class="workflow-new-workflow-grid-item">
                <div>
                    <p>{{ data.createDate }}</p>
                    <h6>{{ data.workflowName }}</h6>
                    <div class="workflow-new-workflow-grid-item-buttons">
                        <button class="go" @click="templateConfigurationData(data.workflowId)">
                            <RouterLink to="/WorkflowPanel">
                                <IconArrowRight size="16" />
                            </RouterLink>
                        </button>
                        <button v-if="ownActive" class="delete" @click="Delete(data.workflowId)">
                            <IconTrash size="16" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconArrowRight, IconTrash } from '@tabler/icons-vue';
import { TemplateList, CustomerId, TemplateConfiguration, TemplateDelete } from '../services/apiServices';
import LoaderComponent from '../LoaderComponent.vue';
import { useWorkflowStore } from '../store/workflowStore';

export default{
    name: 'WorkflowNewWorkflow',
    components: {
        IconArrowRight,
        IconTrash,
        LoaderComponent
    },
    data: () => ({
        generalActive: true,
        ownActive: false,
        typeOfTemplateList: 0,
        workflowTemplateListData: [],
        loaderActive: false
    }),
    mounted(){
        this.WorkflowTemplateListApi()
    },
    methods: {
        async WorkflowTemplateListApi(){
            this.loaderActive = true
            const params = {
                customerId: this.typeOfTemplateList
            }
            this.workflowTemplateListData = await TemplateList(params)
            this.loaderActive = false
        },
        generalTemplate(){
            this.generalActive = true
            this.ownActive = false
            this.typeOfTemplateList = 0
            this.WorkflowTemplateListApi()
        },
        async customerTemplate(){
            this.loaderActive = true
            this.generalActive = false
            this.ownActive = true
            this.typeOfTemplateList = await CustomerId()
            this.WorkflowTemplateListApi()
        },
        async newWorkflowBlank(){
            const store = useWorkflowStore()
            store.typeOfWorkflowPanel = 'crear'
            store.workflowIsCreate = true
            store.workflowPanelNotSaved = false
            store.workflowId = 0
            store.goToTemplates = false
        },
        async templateConfigurationData(id){
            const store = useWorkflowStore()
            store.typeOfWorkflowPanel = 'template'
            store.workflowId = id
            store.goToTemplates = false
            store.workflowIsCreate = false

            const params = {
                workflowId: id
            }
            const configuration = await TemplateConfiguration(params)
            store.workflowConfigurationData = configuration
        },
        async Delete(id){
            const params = {
                workflowId: id
            }
            await TemplateDelete(params)
            await this.customerTemplate()
        }
    }
}
</script>