<!-- eslint-disable vue/attribute-hyphenation -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
    <div class="container-fluid py-3 MensajeInbound-container">
        <div class="row">
            <div class="col-6">
                <p class="workflow-management-filters-label">Tipo de pausa</p>
                <select v-model="params.option" class="form-control form-select">
                    <option v-for="typeOfPause in typeOfPauseList" :key="typeOfPause.id" :value="typeOfPause.id">{{ typeOfPause.name }}</option>
                </select>
            </div>
        </div>
        <div class="row mt-3" v-if="params.option === 70">
            <div class="col-6">
                <p class="workflow-management-filters-label">Pausar Hasta</p>
                <WorkflowInputDate @changeDate="changeDate" @clearDate="clearDate" :defaultDate="params.dateOfOption"/>
            </div>
            <div class="col-6">
                <p class="workflow-management-filters-label">Hora</p>
                <WorkflowTimePicker @changeTime="changeTimeStartTime" :initialData="params.hour"/>
            </div>
        </div>
        <div class="row mt-3" v-if="params.option === 69">
            <div class="col-6">
                <p class="workflow-management-filters-label">Cantidad de Tiempo</p>
                <input v-model="params.timeUnitValue" class="form-control" type="number"/>
            </div>
            <div class="col-6">
                <p class="workflow-management-filters-label">Unidad de Tiempo</p>
                <select v-model="params.timeUnit" class="form-control form-select">
                    <option value="segundo">Segundo</option>
                    <option value="minuto">Minuto</option>
                    <option value="hora">Hora</option>
                    <option value="dia">Día</option>
                </select>
            </div>
            <div class="col-6 mt-3" v-if="params.timeUnit === 'dia'">
                <p class="workflow-management-filters-label">Hasta</p>
                <WorkflowTimePicker @changeTime="changeTimeStartTime" :initialData="params.hour"/>
            </div>
        </div>
        <div class="row" v-if="params.option === 60">
            <div class="col-6 mt-3">
                <p class="workflow-management-filters-label">Hora</p>
                <WorkflowTimePicker @changeTime="changeTimeStartTime" :initialData="params.hour"/>
            </div>
            <div class="col-12 mt-4">
                <div class="input-checkbox">
                    <label for="useDaysOfWeek">Defina un dia (opcional)</label>
                    <input type="checkbox" id="useDaysOfWeek" :checked="params.useDaysOfWeek" v-model="params.useDaysOfWeek"/>
                </div>
            </div>
            <div class="col-6 mt-3" v-if="params.useDaysOfWeek">
                <p class="workflow-management-filters-label">El dia de la Semana</p>
                <select v-model="params.daysOfWeekValue" class="form-control form-select">
                    <option value="Lunes">Lunes</option>
                    <option value="Martes">Martes</option>
                    <option value="Miercoles">Miércoles</option>
                    <option value="Jueves">Jueves</option>
                    <option value="Viernes">Viernes</option>
                    <option value="Sabado">Sábado</option>
                    <option value="Domingo">Domingo</option>
                </select>
            </div> 
        </div>
        <div class="row justify-content-end mt-4">
            <div class="col-auto">
                <button type="button" class="btn btn-primary" @click="Save">Guardar</button>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { ListaTipoDePausa, SaveConfiguration } from './apiServices';
  import WorkflowInputDate from '../../../management/WorkflowInputDate.vue';
  import WorkflowTimePicker from '../../../WorkflowTimePicker.vue';
  
  export default {
    name: 'EnviarSms',
    components: {
      WorkflowInputDate,
      WorkflowTimePicker
    },
    props: {
      nodeId: {
        type: String,
        required: true
      },
      idNodeType: {
        type: Number,
        required: true
      },
      actionType: {
        type: String,
        required: true
      },
      dataForSidebarConfiguration: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      params: {
        option: '',
        hour: '',
        useDaysOfWeek: false,
        daysOfWeekValue: '',
        timeUnit: '',
        timeUnitValue: '',
        dateOfOption: '',
      },
      typeOfPauseList: [],
      firstMounted: 0
    }),
    watch: {
      dataForSidebarConfiguration: {
        handler(newVal) {
          if (Object.keys(newVal).length !== 0) {
            Object.keys(this.params).forEach(key => {
              if (Object.prototype.hasOwnProperty.call(newVal, key) && !['nodeId', 'actionId', 'actionType'].includes(key)) {
                this.params[key] = newVal[key];
              }
            });
          }
        },
        deep: true
      },
      'params.option' (){
        if(this.firstMounted > 0){
          this.params.hour = '';
          this.params.useDaysOfWeek = false;
          this.params.daysOfWeekValue = '';
          this.params.timeUnit = '';
          this.params.timeUnitValue = '';
          this.params.dateOfOption = '';
        }
        this.firstMounted++;
      }
    },
    async mounted() {
      this.typeOfPauseList = await ListaTipoDePausa();
    },
    methods: {
        async Save() {
            const params = {
                ...this.params,
                nodeId: this.nodeId,
                actionId: this.idNodeType,
                actionType: this.actionType
            };
            await SaveConfiguration(params);
        },
        changeDate(data){
            this.params.dateOfOption = data
        },
        clearDate(data){
            this.params.dateOfOption = data
        },
        changeTimeStartTime(time) {
            this.params.hour = time;
        },
    },
  };
  </script>