<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
    <div class="container-fluid py-3 MensajeInbound-container">
        <div class="row">
            <div class="col-12 mt-3">
                <div class="workflow-panel-menu-options-radio-buttons">
                    <label class="workflow-input-radio">
                        <input v-model="params.typeOfTag" type="radio" :value="1">
                        <span class="checkmark"></span>
                        <p>Seleccionar Tag</p>
                    </label>
                    <label class="workflow-input-radio">
                        <input v-model="params.typeOfTag" type="radio" :value="2">
                        <span class="checkmark"></span>
                        <p>Agregar Tag</p>
                    </label>
                </div>
            </div>
            <div v-if="params.typeOfTag === 1" class="col-8 mt-3">
                <p class="workflow-management-filters-label">Seleccionar Tag</p>
                <select
                    v-model="params.selectedTag"
                    class="multisteps-form__select form-control form-select"
                >
                    <option v-for="(item, i) in tagList" :key="i" :value="item.segmentTagId">{{ item.segmentTagName }}</option>
                </select>
            </div>
            <div v-if="params.typeOfTag === 2" class="col-8 mt-3">
                <p class="workflow-management-filters-label">Agregar Tag</p>
                <input v-model="params.tagName" class="form-control"/>
            </div>
        </div>
        
        <div class="row justify-content-end mt-4">
            <div class="col-auto">
                <button type="button" class="btn btn-primary" @click="Save">Guardar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ListaTag, SaveConfiguration } from './apiServices';

export default {
    name: 'EnviarSms',
    props: {
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        actionType: {
            type: String,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        params: {
            typeOfTag: 1,
            tagName: '',
            selectedTag: ''
        },
        tagList: [],
    }),
    watch: {
        'params.typeOfTag': async function (val) {
            if (val === 2) {
                this.params.tagName = '';
            } else {
                this.params.selectedTag = '';
            }
        },
        dataForSidebarConfiguration: {
            handler(newVal) {
                if (Object.keys(newVal).length !== 0) {
                    Object.keys(this.params).forEach(key => {
                        if (Object.prototype.hasOwnProperty.call(newVal, key) && !['nodeId', 'actionId', 'actionType'].includes(key)) {
                            this.params[key] = newVal[key];
                        }
                    });
                }
            },
            deep: true
        }
    },
    async mounted() {
        this.tagList = await ListaTag();
    },
    methods: {
        async Save() {
            const params = {
                ...this.params,
                nodeId: this.nodeId,
                actionId: this.idNodeType,
                actionType: this.actionType
            };
            await SaveConfiguration(params);
        }
    }
};
</script>