import { createRouter, createWebHistory } from "vue-router";
import Landing from "../views/dashboards/Landing.vue";
import AlternativeDefault from "../views/dashboards/AlternativeDefault.vue";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";

// Mis vistas
import Login from "../views/flex/Login.vue";
import ImportarLista from "../views/flex/ImportarLista.vue";
import NuevoContacto from "../views/flex/NuevoContacto.vue";
import NuevoCustomerUser from "../views/pages/Users/NuevoCustomerUser.vue"
import ListaContactos from "../views/flex/ListarContacto.vue";
import ListaOrigenes from "@/views/flex/ListarOrigenes.vue";
import ListaTag from "@/views/flex/ListarTag.vue";
import ListaSetmeng from "@/views/flex/ListarSetmeng.vue";
import NuevoCanal from "@/views/pages/profile/NuevoCanal.vue";
import ListaCanales from "../views/pages/profile/ListarCanales.vue";
import Customer from "../views/pages/Users/Customer.vue";
import CuentaUsuario from "@/views/campannas/Users/UserCampanna.vue"
import RemitenteEmail from "@/views/campannas/Sender/SenderEmailCampanna.vue"
import RemitenteSMS from "@/views/campannas/Sender/SenderSmsCampanna.vue"
import NuevoCustomer from "@/views/pages/Users/NuevoCustomer.vue";
import NuevoTag from "@/views/flex/NuevoTag.vue";
import NuevoSegment from "@/views/flex/NuevoSegment.vue";
import NuevoWorkflow from "@/views/flex/NuevoWorkflow.vue";
import ReporteMT from "@/views/envios/Reportes/ReporteMT.vue";
import ReporteMO from "@/views/envios/Reportes/ReporteMO.vue";
import ReporteEjecutivo from "@/views/envios/Reportes/ReporteEjecutivo.vue";
import ReporteDetallado from "@/views/envios/Reportes/ReporteDetallado.vue";
import Creditos from "@/views/envios/comprar-creditos/Creditos.vue";
//Rutas Admin
import Precios from "@/views/admin/precios/Precio.vue";
import AdminCustomer from "@/views/admin/UserAdmin.vue";
import AdminUserEdit from "@/views/admin/UserEditAdmin.vue";
// Whatsapp
import PerfilesLista from "@/views/envios/Whatsapp-link.vue"
import PerfilesTemplateLista from "@/views/envios/Whatsapp-Template-link.vue"
import NuevoTemplate from "@/views/envios/whatsapp/Add/NuevoTemplate.vue"

// Campañas
import EnvioPlantilla from "@/views/envios/ListarPlantillas.vue";
import ConfiguracionMoSms from "@/views/channels/ConfiguracionMoSMS.vue";
import ConfiguracionMoWhatsapp from "@/views/channels/ConfiguracionMoWhatsapp.vue";
import EnvioPlantillaEmail from "@/views/envios/ListarPlantillasEmail.vue";
import HistorialCompras from "@/views/envios/comprar-creditos/ListarHistorialCompras.vue";
import SMSlink from "@/views/envios/Sms-link.vue";
import EnviosProgramados from "@/views/envios/Programados.vue";
import ContactosLista from "@/views/contactsC/Listas.vue"
import DetallesLista from "@/views/contactsC/DetallesListas.vue"
import BlackList from "@/views/contactsC/BlackList.vue"
import ListaAdd from "@/views/contactsC/ListaAdd.vue"
import BlackListAdd from "@/views/contactsC/BlackListAdd.vue"
import ProgramadaEditAdd from "@/views/envios/edit/ProgramadaEditAdd.vue";
import NuevoUserSms from "@/views/campannas/Users/NuevoUser.vue";
import {tipoUser} from "@/config/config";
import Chatbot from "../views/chatbot/chatbot.vue";
// WORKFLOW
import WorkflowManagement from "../views/workflow/management/WorkflowManagement.vue";
import WorkflowPanel from "../views/workflow/panel/WorkflowPanel.vue";

const routes = [
  // Mis rutas
  {
    path: "/",
    name: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/dashboard/:status?/:plan?",
    name: "Dashboard",
    component: AlternativeDefault
  },
  {
    path: "/dashboard2",
    name: "Dashboard2",
    component: Default
  },
  {
    path: "/canal/sms/envios/plantillas",
    name: "Envio Plantilla",
    component: EnvioPlantilla
  },
  {
    path: "/canal/sms/configuracion/mo",
    name: "Configuracion MO SMS",
    component: ConfiguracionMoSms
  },
  {
    path: "/canal/whatsapp/configuracion/mo",
    name: "Configuracion MO Whatsapp",
    component: ConfiguracionMoWhatsapp
  },
  {
    path: "/canal/email/envios/plantillas",
    name: "Envio Plantilla Email",
    component: EnvioPlantillaEmail
  },
  {
    path: '/envios/sms-link',
    name: 'SMS Landing',
    component: SMSlink
  },
  {
    path: '/envios/envios-programados',
    name: 'Envios Programados',
    component: EnviosProgramados
  },
  {
    path: '/contactos/lista-contactos',
    name: 'Contactos Lista',
    component: ContactosLista
  },
  {
    path: '/contactos/lista-detalles',
    name: 'Detalles Lista',
    component: DetallesLista
  },
  {
    path: '/contactos/lista-negra',
    name: 'BlackList',
    component: BlackList
  },
  {
    path: '/contactos/nueva-lista/:id?',
    name: 'Lista Add',
    component: ListaAdd
  },
  {
    path: '/contactos/nueva-blacklist',
    name: 'BlackList Add',
    component: BlackListAdd
  },
  {
    path: "/envios/nuevo-envio/:sending_sms?",
    name: "Programar Envio",
    component: ProgramadaEditAdd,
  },
  {
    path: "/contactos/importar-lista",
    name: "Importar Lista",
    component: ImportarLista
  },
  {
    path: "/contactos/nuevo-contacto",
    name: "Nuevo Contacto",
    component: NuevoContacto
  },
  {
    path: "/contactos/lista-contactos",
    name: "Lista Contactos",
    component: ListaContactos
  },
  {
    path: "/contactos/lista-origenes",
    name: "Lista Origenes",
    component: ListaOrigenes
  },
  {
    path: "/tag/lista-tag",
    name: "Lista Tag",
    component: ListaTag
  },
  {
    path: "/setmeng/lista-setment",
    name: "Lista Segment",
    component: ListaSetmeng
  },
  {
    path: "/workflow/nuevo-workflow",
    name: "Nuevo Workflow",
    component: NuevoWorkflow
  },
  {
    path: "/dashboards/landing",
    name: "Landing",
    component: Landing
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects
  },
  {
    path: "/canales/lista-canales",
    name: "Lista Canales",
    component: ListaCanales
  },
  {
    path: "/canales/add",
    name: "Nuevo Canal",
    component: NuevoCanal
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/customer/lista-customer",
    name: "Customer",
    component: Customer,
    beforeEnter: (to, from, next) => {
      // Aquí verifica la condición antes de permitir el acceso
      if (tipoUser()) {
        next(); // Permite el acceso a la ruta
      } else {
        next("/dashboard"); // Redirige a otra ruta si la condición no se cumple
      }
    },
  },
  {
    path: "/customer/nuevo-customer",
    name: "Nuevo Customer",
    component: NuevoCustomer,
    beforeEnter: (to, from, next) => {
      // Aquí verifica la condición antes de permitir el acceso
      if (tipoUser()) {
        next(); // Permite el acceso a la ruta
      } else {
        next("/dashboard"); // Redirige a otra ruta si la condición no se cumple
      }
    },
  },
  {
    path: "/cuenta/nuevo-usuario",
    name: "Nuevo User SMS",
    component: NuevoUserSms,
   /* beforeEnter: (to, from, next) => {
      // Aquí verifica la condición antes de permitir el acceso
      if (tipoUser() || tipoSuperUserClient()) {
        next(); // Permite el acceso a la ruta
      } else {
        next("/dashboard"); // Redirige a otra ruta si la condición no se cumple
      }
    },*/
  },
  {
    path: "/cuenta/usuarios",
    name: "Cuenta Usuarios",
    component: CuentaUsuario
  },
  {
    path: "/remitentes/sms",
    name: "Remitentes SMS",
    component: RemitenteSMS
  },
  {
    path: "/remitentes/email",
    name: "Remitentes Email",
    component: RemitenteEmail
  },
  {
    path: "/customer/customer-user",
    name: "Nuevo Customer User",
    component: NuevoCustomerUser
  },
  {
    path: "/tag/tag-new",
    name: "Nuevo Tag",
    component: NuevoTag
  },
  {
    path: "/segment/segment-new",
    name: "Nuevo Segment",
    component: NuevoSegment
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: '/chatbot/prueba',
    name: 'Chatbot Prueba',
    component: Chatbot
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500
  },
  {
    path: '/reportes/reporte-mt',
    name: 'Reporte MT',
    component: ReporteMT
  },
  {
    path: '/reportes/reporte-mo',
    name: 'Reporte MO',
    component: ReporteMO
  },
  {
    path: '/reportes/reporte-ejecutivo',
    name: 'Reporte Ejecutivo',
    component: ReporteEjecutivo
  },
  {
    path: '/reportes/reporte-detallado',
    name: 'Reporte Detallado',
    component: ReporteDetallado
  },
  {
    path: '/cuenta/comprar-creditos/:payProductId?',
    name: 'Comprar Creditos',
    component: Creditos
  },
  {
    path: '/admin/customer/lista',
    name: 'Admin Customer',
    component: AdminCustomer
  },
  {
    path: '/admin/user/edit',
    name: 'Admin User Edit',
    component: AdminUserEdit
  },
  {
    path: '/admin/precios/lista',
    name: 'Precio',
    component: Precios
  },
    //Rutas Whataspp
  {
    path: '/canal/whatsapp/lista-perfiles',
    name: 'Lista Perfiles',
    component: PerfilesLista
  },
  {
    path: '/canal/whatsapp/lista-templates',
    name: 'Lista Templates',
    component: PerfilesTemplateLista
  },
  {
    path: '/whatsapp/nuevo-templates/',
    name: 'Nuevo Template',
    component: NuevoTemplate
  },
  {
    path: '/cuenta/historial-compras',
    name: 'Historial de Compras',
    component: HistorialCompras
  },
  {
    path: '/WorkflowPanel',
    name: 'WorkflowPanel',
    component: WorkflowPanel
  },
  {
    path: '/WorkflowManagement',
    name: 'WorkflowManagement',
    component: WorkflowManagement
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

export default router;
