<template>
    <div class="flatpickr-input-box">
        <input ref="datePicker" placeholder="Seleccionar" :value="defaultDate"/>
        <button @click="clearDate">
            <IconX size="14" stroke-width="2.5"/>
        </button>
    </div>
</template>

<script>
import { IconX } from '@tabler/icons-vue';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

export default {
    name: 'InputDate',
    components: {
        IconX
    },
    props: {
        defaultDate: {
            type: String,
            default: ''
        }
    },
    emits: ['changeDate', 'clearDate'],
    data() {
        return {
            flatpickrInstance: null,
        };
    },
    mounted() {
        this.flatpickrInstance = flatpickr(this.$refs.datePicker, {
        dateFormat: 'd-m-y',
        enableTime: false,
        onChange: (selectedDates) => {
            if (selectedDates.length > 0) {
                const selectedDate = selectedDates[0];
                const formattedDate = selectedDate.toLocaleDateString('es-ES');
                this.$emit('changeDate', formattedDate)
            }
        },
        });
    },
    beforeUnmount() {
        if (this.flatpickrInstance) {
        this.flatpickrInstance.destroy();
        }
    },
    methods: {
        clearDate(){
            this.flatpickrInstance.clear()
            this.$emit('clearDate', '')
        }
    }
}
</script>

<style scoped>
.flatpickr-input-box{
    position: relative;
}

.flatpickr-input-box button{
    position: absolute;
    top: 49%;
    transform: translateY(-50%);
    right: 4px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #8e8f8f;
}

.flatpickr-input-box button:hover{
    color: #C55845;
}

.flatpickr-input{
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #4f575f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d6da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
    outline: none;
}

.flatpickr-input:focus{
    border: 1px solid #0033A0;
}
</style>
  