<!-- eslint-disable vue/attribute-hyphenation -->
<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="container-fluid py-3 MensajeInbound-container">
      <div class="row">
          <div class="col-12 mt-3">
            <p class="workflow-management-filters-label">Ingresar URL</p>
            <input v-model="params.url" class="form-control"/>
          </div>
          <div class="col-12 mt-4">
            <div class="input-checkbox">
              <label for="security">Requerie seguridad</label>
              <input type="checkbox" id="security" :checked="params.security" v-model="params.security"/>
            </div>
          </div>
          <div class="col-6 mt-3">
            <p class="workflow-management-filters-label">Ingresar Token</p>
            <input v-model="params.token" class="form-control" :disabled="!params.security"/>
          </div>
          <div class="col-12 mt-4">
            <DynamicInput
              :selectOptions="headersList"
              :contentEditableDiv="params.payload"
              @updateContent="updateContentEditableDiv"
            />
          </div>
      </div>
      <div class="row justify-content-end mt-4">
          <div class="col-auto">
              <button type="button" class="btn btn-primary" @click="Save">Guardar</button>
          </div>
      </div>
  </div>
</template>

<script>
import { ListaHeaders, SaveConfiguration } from './apiServices';
import DynamicInput from './DynamicInput.vue';

export default {
  name: 'EnviarSms',
  components: {
    DynamicInput
  },
  props: {
    nodeId: {
      type: String,
      required: true
    },
    idNodeType: {
      type: Number,
      required: true
    },
    actionType: {
      type: String,
      required: true
    },
    dataForSidebarConfiguration: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    params: {
      url: '',
      security: false,
      payload: undefined,
      token: ''
    },
    headersList: [],
  }),
  watch: {
    dataForSidebarConfiguration: {
      handler(newVal) {
        if (Object.keys(newVal).length !== 0) {
          Object.keys(this.params).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(newVal, key) && !['nodeId', 'actionId', 'actionType'].includes(key)) {
              this.params[key] = newVal[key];
            }
          });
        }
      },
      deep: true
    },
    'params.security': {
      handler(newVal) {
        if (!newVal) {
          this.params.token = '';
        }
      }
    }
  },
  async mounted() {
    this.headersList = await ListaHeaders();
  },
  methods: {
    async Save() {
      const params = {
        ...this.params,
        nodeId: this.nodeId,
        actionId: this.idNodeType,
        actionType: this.actionType
      };
      await SaveConfiguration(params);
    },
    updateContentEditableDiv(content) {
      this.params.payload = content;
    }
  },
};
</script>