<!-- eslint-disable vue/attribute-hyphenation -->
<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="workflow">
    <transition name="WorkflowNewActivatorFade">
      <LoaderComponent v-if="loader"/>
    </transition>
    <div class="workflow-panel">
      <div class="workflow-panel-newActivator">
          <button :disabled="workflowIsCreate" @click="LoadActivatorList()">
              <span>Agregar Activador</span>
          </button>
          <transition name="WorkflowNewActivatorFade">
            <div v-if="workflowIsCreate" class="workflow-panel-newActivator-notAllowed-message">
              <p>Si quieres agregar un activador, primero debe de registrar el nuevo workflow.</p>
              <IconClick size="100"/>
            </div>
          </transition>
          <transition name="WorkflowNewActivatorFade">
            <div v-if="WorkflowNewActivatorActive" class="workflow-panel-newActivator-content">
              <button v-for="(data, i) in activators" :key="i" class="workflow-panel-newActivator-content-option" @click="addCustomNode(data); WorkflowNewActivatorActive = false">
                {{ data.activatorName }}
              </button>
            </div>
          </transition>
      </div>
      <div class="workflow-panel-menu">
        <div class="workflow-panel-menu-title">
          <p>{{ workflowName }}</p>
        </div>
        <div class="workflow-panel-menu-icon" @click="WorkflowMenuOptionsActivate = !WorkflowMenuOptionsActivate">
          <IconCategory2 size="18" />
        </div>
        <transition name="WorkflowNewActivatorFade">
          <div v-if="WorkflowMenuOptionsActivate" class="workflow-panel-menu-options">
            <div>
              <p class="workflow-management-filters-label">Nombre del Worflow</p>
              <input v-model="inputWorkflowName" type="text" class="form-control" placeholder="Escribir">
            </div>
            <div class="workflow-panel-menu-options-radio-buttons">
              <label class="workflow-input-radio">
                <input v-model="typeOfWorkflow" type="radio" :value="false" name="workflowName">
                <span class="checkmark"></span>
                <p>Workflow</p>
              </label>
              <label class="workflow-input-radio">
                <input v-model="typeOfWorkflow" type="radio" :value="true" name="workflowName">
                <span class="checkmark"></span>
                <p>Template</p>
              </label>
            </div>
            <div class="workflow-panel-menu-options-button">
              <button :disabled="inputWorkflowName.length === 0" @click="Create">Guardar</button>
            </div>
          </div>
        </transition>
      </div>
      <VueFlow :nodes="nodes" :edges="edges">
        <template #node-custom="customNodeProps">
          <WorkflowCustomNode v-bind="customNodeProps" @addOptionForNode="addOptionForNode" @deleteCustomNode="deleteCustomNode" @nodeInfo="nodeInfo"/>
        </template>
      </VueFlow>
      <WorkflowSidebar
        :active="WorkflowSidebarActive"
        :typeOfSidebar="toRaw(infoForAttribute).icon || toRaw(infoForAttribute).title || ''"
        :titleOfSidebar="toRaw(infoForAttribute).title || ''"
        :descriptionOfSidebar="toRaw(infoForAttribute).description || ''"
        :nodeId="toRaw(infoForAttribute).id || ''"
        :idNodeType="idNodeType || 0"
        :dataForSidebarConfiguration="toRaw(dataForSidebarConfiguration)"
        :actionType="toRaw(infoForAttribute).nodeType || ''"
        @closeSidebar="closeSidebar"
        @createStructureForAttribute="createStructureForAttribute"
        @deleteStructureForAttribute="deleteStructureForAttribute"
      />
      <div class="workflow-panel-changeHistory">
        <transition name="WorkflowNewActivatorFade">
          <div v-if="workflowPanelNotSaved" class="workflow-panel-changeHistory-notSaved" @click="WorkflowMenuOptionsActivate = true">
            <p>Existen cambios que aún no han sido guardados</p>
            <IconPointer size="18" />
          </div>
        </transition>
        <transition name="WorkflowNewActivatorFade">
          <div v-if="changeHistoryActive" class="workflow-panel-changeHistory-changesList" :style="`--changeListHeight: ${changeListHeight}`">
            <div class="workflow-panel-changeHistory-changesList-title" @click="changeHistoryToggle">
              <h5>Historial de cambios</h5>
              <IconCircleChevronDown size="24" stroke-width="2"/>
            </div>
            <div class="workflow-panel-changeHistory-changesList-items">
              <div v-for="(data, i) in store.changeHistory" :key="i" class="workflow-panel-changeHistory-changesList-item">
                <div>
                  <IconSquareRoundedPlus v-if="data.symbol === 'positive'" size="16" color="#208C25"/>
                  <IconSquareRoundedMinus v-if="data.symbol === 'negative'" size="16" color="#C55845"/>
                  <p>{{ data.description }}</p>
                </div>
                <p>{{ data.hour }}</p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRaw, computed, watch, onMounted, onUnmounted } from 'vue'
import { VueFlow, useVueFlow } from '@vue-flow/core'
import WorkflowCustomNode from './WorkflowCustomNode.vue'
import WorkflowSidebar from './WorkflowSidebar.vue';
import { ActivatorList, CreateWorkflowAndTemplate, WorkflowConfiguration, ActionList } from '../services/apiServices';
import { useWorkflowStore } from '../store/workflowStore';
import { IconCategory2, IconSquareRoundedPlus, IconPointer, IconSquareRoundedMinus, IconCircleChevronDown, IconClick } from '@tabler/icons-vue';
import { 
  ObtainAudienciaPredefinida, 
  ObtainMensajeInbound, 
  ObtainFechaHora, 
  ObtainCambioPerfil, 
  ObtainEventoTiempoReal,
  ObtainEnviarSms,
  ObtainEnviarEmail,
  ObtainEnviarWhatsapp,
  ObtainAgregarTag,
  ObtainBorrarTag,
  ObtainLlamadaApi,
  ObtainSegmentarAudiencia,
  ObtainPausar,
  ObtainEsperarEvento } from '../services/obtainSidebarConfiguration';
import { useRouter } from 'vue-router';
import LoaderComponent from '../LoaderComponent.vue';

let WorkflowNewActivatorActive = ref(false)
let WorkflowSidebarActive = ref(false)

const nodes = ref([])
const edges = ref([])

// CURRENT TIME
function currentTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

// STORE
const store = useWorkflowStore()

// ROUTER
const router = useRouter()

// FUNCION QUE SE EJECUTA CUANDO SE HACE UNA RELOAD DE LA PAGINA
const handleReload = () => {
  sessionStorage.setItem('nodesLocalStorage', JSON.stringify(toRaw(nodes.value)));
  sessionStorage.setItem('edgesLocalStorage', JSON.stringify(toRaw(edges.value)));
  sessionStorage.setItem('workflowPanelNotSavedStorage', JSON.stringify(toRaw(workflowPanelNotSaved.value)))
  sessionStorage.setItem('storeWorkflowIdStorage', JSON.stringify(toRaw(storeWorkflowId.value)))
};

// EVENTOS QUE SE MANEJAN CUANDO SE MONTA EL COMPONENTE
onMounted(() => {
  // EVENT LISTENER QUE SE EJECUTA JUSTO ANTES DE REALIZAR EL RELOAD DE LA PAGINA
  window.addEventListener('beforeunload', handleReload);

  const nodesLocalStorage = sessionStorage.getItem('nodesLocalStorage')
  const edgesLocalStorage = sessionStorage.getItem('edgesLocalStorage')

  // SE USA PARA MOSTRAR EL MENSAJE DE CAMBIOS SIN GUARDAR ASI SE HAGA UN RELOAD
  const workflowPanelNotSavedStorage = sessionStorage.getItem('workflowPanelNotSavedStorage')
  workflowPanelNotSavedStorage ? store.workflowPanelNotSaved = JSON.parse(workflowPanelNotSavedStorage) : ''

  // SE USA PARA OBTENER EL ID DEL WORKFLOW Y HACER LA CONSULTA PARA OBTENER LOS DATOS DESPUES DE UN RELOAD
  const storeWorkflowIdStorage = sessionStorage.getItem('storeWorkflowIdStorage')
  storeWorkflowIdStorage ? store.workflowId = JSON.parse(storeWorkflowIdStorage) : ''

  if(storeWorkflowIdStorage && store.workflowId !== 0){
    setTimeout(async () => {
      const data = await WorkflowConfiguration({workflowId: store.workflowId})
      workflowName.value = data.workflowName
      inputWorkflowName.value = data.workflowName
      typeOfWorkflow.value = data.isTemplate
      store.workflowIsCreate = false
    }, 300)
  }

  if(nodesLocalStorage){
    setTimeout(() => {
      nodes.value = JSON.parse(nodesLocalStorage)
      edges.value = JSON.parse(edgesLocalStorage)
    }, 300)
  }
});

// EVENETOS QUE SE EJECUTAN AL MOMENTO DE DESMONTAR EL COMPONENTE
// ESTOS EVENTOS NO SE EJECUTAN AL HACER REALOAD
onUnmounted(() => {
  sessionStorage.removeItem('nodesLocalStorage');
  sessionStorage.removeItem('edgesLocalStorage');
  sessionStorage.removeItem('workflowPanelNotSavedStorage');
  sessionStorage.removeItem('storeWorkflowIdStorage');
  store.changeHistory = []
});

// CONJUNTO DE VARIABLES QUE SIRVEN PARA MOSTRAR LOS DATOS DE APOYO AL USUARIO
const workflowIsCreate = computed(() => store.workflowIsCreate)
const workflowPanelNotSaved = computed(() => store.workflowPanelNotSaved)
const changeHistoryActive = ref(false)
const loader = computed(() => store.loaderWorkflowPanel)

// FUNCION PARA AGREGAR UN ACTIVADOR
function addCustomNode(info){
  const idNode = Date.now().toString()
  nodes.value.push({
    id: idNode,
    data: {
      icon: info.frontType,
      title: info.activatorName,
      description: info.description,
      id: idNode,
      type: 'activatorNode',
      availableAddOption: false,
      parent: idNode
    },
    type: 'custom',
    position: {
      x: 100,
      y: 100
    }
  })
  
  counterAttributePositionY.value = 1

  // EVENTO PARA GRABADO DEL ESTADO PARA MENSAJE DE CAMBIOS NO GUARDADOS
  store.workflowPanelNotSaved = true
  sessionStorage.setItem('workflowPanelNotSavedStorage', JSON.stringify(toRaw(workflowPanelNotSaved.value)))
  
  // EVENTOS PARA MANEJO DEL CUADRO DE CHANGES HISTORY
  changeHistoryActive.value = true
  store.changeHistory.push({
    symbol: 'positive',
    description: `Se agregó activador "${info.activatorName}"`,
    hour: currentTime(),
  })
  store.changeHistory.length <= 1 ? changeListHeight.value = 0 : changeListHeight.value = store.changeHistory.length - 1
}

// FUNCION PARA BUSCAR EL NODO POR ID Y ACTUALIZAR SU POSICION
function updateNodePosition (id, newPosition) {
  const index = nodes.value.findIndex(node => node.id === id)
  toRaw(nodes.value[index]).position = newPosition
}

// EVENTOS VUEFLOW
const {
  onNodeDoubleClick,
  onNodeDragStop
} = useVueFlow()

const DoubleClickAllowedNode = ['Atributo']

onNodeDoubleClick((event) => {
  const iconWithoutSpaces = toRaw(infoForAttribute.value).title.replace(/\s+/g, '');
  ObtainDataForSidebar(toRaw(infoForAttribute.value).id, iconWithoutSpaces);
  if(DoubleClickAllowedNode.includes(toRaw(event.node.data.title))){
    WorkflowSidebarActive.value = false
  } else {
    WorkflowSidebarActive.value = true
  }
})

// OBTENER CONFIGURTACION DEL SIDEBAR SEGUN EL NODO
let dataForSidebarConfiguration = ref({})

async function ObtainDataForSidebar(id, type){
    const functionList = {
      /* ACTIVATORS */
      AudienciaPredefinida: ObtainAudienciaPredefinida,
      MensajeInbound: ObtainMensajeInbound,
      FechaHora: ObtainFechaHora,
      CambioPerfil: ObtainCambioPerfil,
      EventoTiempoReal: ObtainEventoTiempoReal,
      /* ACTIONS */
      EnviarSMS: ObtainEnviarSms,
      EnviarEmail: ObtainEnviarEmail,
      EnviarWhatsapp: ObtainEnviarWhatsapp,
      /* FUNCTIONS */
      Agregartag: ObtainAgregarTag,
      Borrartag: ObtainBorrarTag,
      LlamadaAPI: ObtainLlamadaApi,
      Segmentaraudiencia: ObtainSegmentarAudiencia,
      Pausar: ObtainPausar,
      Esperarunevento: ObtainEsperarEvento
    }
    const params = {
      nodeId: id
    }
    if(functionList[type]){
      dataForSidebarConfiguration.value = await functionList[type](params)
    }
}

onNodeDragStop((event) => {
  const id = toRaw(event.node.id)
  const position = toRaw(event.node.position)
  updateNodePosition(id, position)

  // EVENTO PARA GRABADO DEL ESTADO PARA MENSAJE DE CAMBIOS NO GUARDADOS
  store.workflowPanelNotSaved = true
  sessionStorage.setItem('workflowPanelNotSavedStorage', JSON.stringify(toRaw(workflowPanelNotSaved.value)))
})

// FUNCION QUE EMITE EL SIDEBAR
function closeSidebar(){
  WorkflowSidebarActive.value = false
}

// FUNCION PARA AGREGAR ATRIBUTO DESDE EL SIDEBAR
let infoForAttribute = ref({})

function createStructureForAttribute(){
  const info = toRaw(infoForAttribute.value)
  const index = nodes.value.findIndex(node => node.id === info.id)
  const nodeSelectedPosition = toRaw(nodes.value[index]).position
  const infoEdgeData = {
    actionChannelId: 0,
    name: `Atributo - ${info.title}`,
    description: "",
    frontType: ""
  }
  const infoEdge = {
    data: infoEdgeData,
    idParent: info.id,
    nodeType: ''
  }
  createAttribute(nodeSelectedPosition, infoEdge)

  // EVENTO PARA GRABADO DEL ESTADO PARA MENSAJE DE CAMBIOS NO GUARDADOS
  store.workflowPanelNotSaved = true
  sessionStorage.setItem('workflowPanelNotSavedStorage', JSON.stringify(toRaw(workflowPanelNotSaved.value)))
}

const counterAttributePositionY = ref(1)

function createAttribute(infoNode, infoEdge){
  const idNode = Date.now().toString() + infoEdge.idParent
  const positionY = infoNode.y + (counterAttributePositionY.value * 120)
  counterAttributePositionY.value ++

  nodes.value.push({
    id: idNode,
    data: {
      icon: infoEdge.data.name,
      title: infoEdge.data.name,
      description: infoEdge.data.description,
      id: idNode,
      type: 'optionNode',
      availableAddOption: false,
      parent: infoEdge.idParent,
      nodeType: infoEdge.nodeType,
      nodeTypeId: infoEdge.data.actionChannelId ? infoEdge.data.actionChannelId : infoEdge.data.actionFunctionId
    },
    type: 'custom',
    position: {
      x: infoNode.x + 50,
      y: positionY
    }
  })

  infoEdge.idChild = idNode

  addOptionEdge(infoEdge)
  store.setLastAttributeCreate(idNode)

  if(infoEdge.idParent !== null){
    const index = nodes.value.findIndex(node => node.id === infoEdge.idParent)
    toRaw(nodes.value[index]).data.availableAddOption = true
  }

  // EVENTOS PARA MANEJO DEL CUADRO DE CHANGES HISTORY
  store.changeHistory.push({
    symbol: 'positive',
    description: `Se agregó atributo "${infoEdge.data.name}"`,
    hour: currentTime(),
  })
  store.changeHistory.length <= 1 ? changeListHeight.value = 0 : changeListHeight.value = store.changeHistory.length - 1
}

function deleteStructureForAttribute(id){
  counterAttributePositionY.value = 1
  nodes.value = nodes.value.filter(objeto => objeto.id !== id)
  edges.value = edges.value.filter(objeto => objeto.target !== id)

  // EVENTO PARA GRABADO DEL ESTADO PARA MENSAJE DE CAMBIOS NO GUARDADOS
  store.workflowPanelNotSaved = true 
  sessionStorage.setItem('workflowPanelNotSavedStorage', JSON.stringify(toRaw(workflowPanelNotSaved.value)))
}

// FUNCION PARA OBTENER INFORMACION DEL NODO SELECCIONADO
let idNodeType = ref(null)
let actionsList = ref([])

async function nodeInfo(data){
  infoForAttribute.value = data
  /* if (data.title === 'Enviar SMS') {
    infoForAttribute.value.icon = 'Enviar SMS';
  } */
  if(activators.value.length === 0 || actionsList.value.length === 0){
    activators.value = await ActivatorList()
    actionsList.value = await ActionList()
  }
  if(data.type === 'activatorNode'){
    const typeOfNodeSelected = toRaw(activators.value).find(usuario => usuario.frontType === infoForAttribute.value.icon);
    idNodeType.value = typeOfNodeSelected.activatorId
  }
  if(data.type === 'optionNode' && data.nodeType === 'canales'){
    const typeOfNodeSelected = toRaw(actionsList.value).Canales.find(usuario => usuario.name === infoForAttribute.value.title);
    idNodeType.value = typeOfNodeSelected.actionChannelId
  }
  if(data.type === 'optionNode' && data.nodeType === 'funciones'){
    const typeOfNodeSelected = toRaw(actionsList.value).Funciones.find(usuario => usuario.name === infoForAttribute.value.title);
    idNodeType.value = typeOfNodeSelected.actionFunctionId
  }
}

function addOptionNode(infoNode, infoEdge){
  const idNode = Date.now().toString() + infoEdge.idParent
  nodes.value.push({
    id: idNode,
    data: {
      icon: infoEdge.data.name,
      title: infoEdge.data.name,
      description: infoEdge.data.description,
      id: idNode,
      type: 'optionNode',
      availableAddOption: false,
      parent: infoEdge.idParent,
      nodeType: infoEdge.nodeType,
      nodeTypeId: infoEdge.data.actionChannelId ? infoEdge.data.actionChannelId : infoEdge.data.actionFunctionId
    },
    type: 'custom',
    position: {
      x: infoNode.x + 100,
      y: infoNode.y + 150
    }
  })

  infoEdge.idChild = idNode

  addOptionEdge(infoEdge)

  if(infoEdge.idParent !== null){
    const index = nodes.value.findIndex(node => node.id === infoEdge.idParent)
    toRaw(nodes.value[index]).data.availableAddOption = true
  }

  // EVENTOS PARA MANEJO DEL CUADRO DE CHANGES HISTORY
  store.changeHistory.push({
    symbol: 'positive',
    description: `Se agregó acción "${infoEdge.data.name}"`,
    hour: currentTime(),
  })
  store.changeHistory.length <= 1 ? changeListHeight.value = 0 : changeListHeight.value = store.changeHistory.length - 1
}

function addOptionEdge(infoEdge){
  const idEdge = infoEdge.idParent + infoEdge.idChild
  edges.value.push({
    id:  idEdge,
    source: infoEdge.idParent,
    target: infoEdge.idChild
  })
}

function addOptionForNode(data){
  // este es el ID del nodo padre al que quieres agregar un child
  const idNodeSelected = data[1]
  // obtener posicion del nodo selccionado
  const index = nodes.value.findIndex(node => node.id === idNodeSelected)
  const nodeSelectedPosition = toRaw(nodes.value[index]).position
  const infoEdge = {
    data: data[0],
    idParent: idNodeSelected,
    nodeType: data[2]
  }
  addOptionNode(nodeSelectedPosition, infoEdge)
  store.workflowPanelNotSaved = true
  sessionStorage.setItem('workflowPanelNotSavedStorage', JSON.stringify(toRaw(workflowPanelNotSaved.value)))
}

function deleteCustomNode(data){
  counterAttributePositionY.value = 1
  const nodeDeleted = toRaw(nodes.value).filter(objeto => objeto.id.includes(data[0]))[0]

  store.changeHistory.push({
    symbol: 'negative',
    description: `Se eliminó "${nodeDeleted.data.title}"`,
    hour: currentTime(),
  })

  store.changeHistory.length <= 1 ? changeListHeight.value = 0 : changeListHeight.value = store.changeHistory.length - 1

  edges.value = edges.value.filter(objeto => !objeto.id.includes(data[0]));
  nodes.value = nodes.value.filter(objeto => !objeto.id.includes(data[0]));

  if(nodes.value.some(objeto => objeto.id === data[1])){
    const indice = nodes.value.findIndex(producto => producto.id === data[1]);
    nodes.value[indice].data.availableAddOption = false
  }
  store.workflowPanelNotSaved = true
  sessionStorage.setItem('workflowPanelNotSavedStorage', JSON.stringify(toRaw(workflowPanelNotSaved.value)))
}

// DESACTIVA LOS WARNING DE LOS EVENTOS GENERADOS POR VUEFLOW
const originalConsoleWarn = console.warn;
console.warn = (...args) => {
  if (args[0].includes('event')) {
    return;
  }
  originalConsoleWarn.apply(console, args);
};

// APIs
let activators = ref([])

async function LoadActivatorList(){
  WorkflowNewActivatorActive.value = !WorkflowNewActivatorActive.value
  if(activators.value.length === 0){
    activators.value = await ActivatorList()
  }
}

// GUARDAR Y CREAR WORKFLOW
let WorkflowMenuOptionsActivate = ref(false)
const inputWorkflowName = ref('')
const typeOfWorkflow = ref(false)

const workflowBody = {
  workflowId: store.workflowId,
  workflowName: '',
  customerId: 0,
  isTemplate: false,
  nodes: null,
  edges: null
}

async function Create(){
  workflowBody.nodes = toRaw(nodes.value)
  workflowBody.edges = toRaw(edges.value)
  await CreateWorkflowAndTemplate(workflowBody)
  WorkflowMenuOptionsActivate.value = false
  store.workflowIsCreate = false
  store.workflowPanelNotSaved = false
  sessionStorage.setItem('workflowPanelNotSavedStorage', JSON.stringify(toRaw(workflowPanelNotSaved.value)))

  if(store.typeOfWorkflowPanel === 'editar' && typeOfWorkflow.value){
    store.goToTemplates = true
    router.push('/WorkflowManagement')
  } else if(store.typeOfWorkflowPanel === 'template' && !typeOfWorkflow.value){
    router.push('/WorkflowManagement')
  }
}

watch(inputWorkflowName, (newValue) => {
  workflowBody.workflowName = newValue
  workflowName.value = newValue
  workflowBody.customerId = store.customerId
})

const storeWorkflowId = computed(() => store.workflowId)

watch(storeWorkflowId, (newValue) => {
  if(newValue === 0){
    workflowBody.workflowId = 0
  } else {
    workflowBody.workflowId = store.workflowId
  }
})

watch(typeOfWorkflow, (newValue) => {
  workflowBody.isTemplate = newValue

  if(store.typeOfWorkflowPanel === 'editar' && newValue){
    workflowBody.workflowId = 0
  } else if(store.typeOfWorkflowPanel === 'editar' && !newValue){
    workflowBody.workflowId = store.workflowId
  }

  if(store.typeOfWorkflowPanel === 'template' && !newValue){
    workflowBody.workflowId = 0
  } else if(store.typeOfWorkflowPanel === 'template' && newValue){
    workflowBody.workflowId = store.workflowId
  }
})

// OBTENER CONFIGURACION GUARDADA DEL WORKFLOW EN EL STORE
const vueFlowData = computed(() => store.workflowConfigurationData)
const workflowName = ref('')

watch(vueFlowData, (newValue) => {
  const data = toRaw(newValue)
  nodes.value = data.nodes
  edges.value = data.edges
  workflowName.value = data.workflowName
  inputWorkflowName.value = data.workflowName
  typeOfWorkflow.value = data.isTemplate
  store.loaderWorkflowPanel = false
})

const changeListHeight = ref(0)

function changeHistoryToggle(){
  store.changeHistory.length <= 1 ? changeListHeight.value = 0 : changeListHeight.value = store.changeHistory.length - 1
  const changeHistoryDiv = document.querySelector('.workflow-panel-changeHistory-changesList')
  changeHistoryDiv.classList.toggle('short')
}
</script>