<!-- eslint-disable vue/v-on-event-hyphenation -->
<!-- eslint-disable vue/attribute-hyphenation -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
    <transition name="WorkflowSidebarFade">
        <div v-if="active" class="workflow-sidebar">
            <div class="workflow-sidebar-content">
                <div class="workflow-sidebar-content-close" @click="closeSidebar">
                    <IconX size="24" stroke-width="3"/>
                </div>
                <div class="workflow-sidebar-content-summary">
                    <div class="workflow-sidebar-content-summary-title">
                        <component :is="iconComponent" size="20" color="#000" />
                        <h3>{{ titleOfSidebar }}</h3>
                    </div>
                    <p>{{ descriptionOfSidebar }}</p>
                    <div v-if="sidebarMessageNotSaved" class="workflow-sidebar-content-notSaved">
                        <span>No se olvide de guardar su configuración antes de cerrar el sidebar</span>
                    </div>
                </div>
                <!-- ACTIVATORS -->
                <AudienciaPredefinida v-if="typeOfSidebar === 'AudienciaPredefinida'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :descriptionOfSidebar="descriptionOfSidebar"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                />
                <MensajeInbound v-if="typeOfSidebar === 'MensajeInbound'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    @createStructureForAttribute="createStructureForAttribute"
                    @deleteStructureForAttribute="deleteStructureForAttribute"
                    @attributesAddNotSaved="attributesAddNotSaved"
                />
                <FechaHora v-if="typeOfSidebar === 'FechaHora'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                />
                <EventoTiempoReal v-if="typeOfSidebar === 'EventoTiempoReal'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    @createStructureForAttribute="createStructureForAttribute"
                    @deleteStructureForAttribute="deleteStructureForAttribute"
                    @attributesAddNotSaved="attributesAddNotSaved"
                />
                <Api v-if="typeOfSidebar === 'Api'"/>
                <CambioPerfil v-if="typeOfSidebar === 'CambioPerfil'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    @createStructureForAttribute="createStructureForAttribute"
                    @deleteStructureForAttribute="deleteStructureForAttribute"
                    @attributesAddNotSaved="attributesAddNotSaved"
                />
                <ImportacionSFTP v-if="typeOfSidebar === 'WorkflowExistente'"/>
                <!-- ACTIONS -->
                <EnviarSms v-if="typeOfSidebar === 'Enviar SMS'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                />
                <EnviarEmail v-if="typeOfSidebar === 'Enviar Email'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                />
                <EnviarWhatsapp v-if="typeOfSidebar === 'Enviar Whatsapp'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                />
                <!-- FUNCTIONS -->
                <EsperarEvento v-if="typeOfSidebar === 'Esperar un evento'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    @createStructureForAttribute="createStructureForAttribute"
                    @deleteStructureForAttribute="deleteStructureForAttribute"
                    @attributesAddNotSaved="attributesAddNotSaved"
                />
                <AgregarTag v-if="typeOfSidebar === 'Agregar tag'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                />
                <BorrarTag v-if="typeOfSidebar === 'Borrar tag'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                />
                <LlamadaApi v-if="typeOfSidebar === 'Llamada API'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                />
                <SegmentarAudiencia v-if="typeOfSidebar === 'Segmentar audiencia'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                    @createStructureForAttribute="createStructureForAttribute"
                    @deleteStructureForAttribute="deleteStructureForAttribute"
                    @attributesAddNotSaved="attributesAddNotSaved"
                />
                <Pausar v-if="typeOfSidebar === 'Pausar'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                />
                <EvaluarDatos v-if="typeOfSidebar === 'Evaluar datos'"
                    :nodeId="nodeId" 
                    :idNodeType="idNodeType"
                    :dataForSidebarConfiguration="dataForSidebarConfiguration"
                    :actionType="actionType"
                    @createStructureForAttribute="createStructureForAttribute"
                    @deleteStructureForAttribute="deleteStructureForAttribute"
                    @attributesAddNotSaved="attributesAddNotSaved"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import { IconX, IconClipboard } from '@tabler/icons-vue';
import { iconMapper } from './iconMapper';
import { useWorkflowStore } from '../store/workflowStore';
// SIDEBAR ACTIVATORS
import AudienciaPredefinida from './activators/AudienciaPredefinida/AudienciaPredefinida.vue';
import MensajeInbound from './activators/MensajeInbound/MensajeInbound.vue';
import EventoTiempoReal from './activators/EventoTiempoReal/EventoTiempoReal.vue';
import Api from './activators/Api/Api.vue';
import CambioPerfil from './activators/CambioPerfil/CambioPerfil.vue';
import FechaHora from './activators/FechaHora/FechaHora.vue';
import ImportacionSFTP from './activators/ImportacionSFTP/ImportacionSFTP.vue';
// SIDEBAR ACTIONS
import EnviarSms from './actions/EnviarSms/EnviarSms.vue';
import EnviarEmail from './actions/EnviarEmail/EnviarEmail.vue';
import EnviarWhatsapp from './actions/EnviarWhatsapp/EnviarWhatsapp.vue';
// SIDEBAR FUNCTIONS
import EsperarEvento from './functions/EsperarEvento/EsperarEvento.vue';
import AgregarTag from './functions/AgregarTag/AgregarTag.vue';
import BorrarTag from './functions/BorrarTag/BorrarTag.vue';
import LlamadaApi from './functions/LlamadaApi/LlamadaApi.vue';
import SegmentarAudiencia from './functions/SegmentarAudiencia/SegmentarAudiencia.vue';
import Pausar from './functions/Pausar/Pausar.vue';
import EvaluarDatos from './functions/EvaluarDatos/EvaluarDatos.vue';

export default{
    name: 'WorkflowSidebar',
    components: {
        IconX,
        IconClipboard,
        AudienciaPredefinida,
        MensajeInbound,
        EventoTiempoReal,
        Api,
        CambioPerfil,
        FechaHora,
        ImportacionSFTP,
        EnviarSms,
        EnviarEmail,
        EnviarWhatsapp,
        EsperarEvento,
        AgregarTag,
        BorrarTag,
        LlamadaApi,
        SegmentarAudiencia,
        Pausar,
        EvaluarDatos
    },
    props: {
        active: {
            type: Boolean,
            required: true
        },
        typeOfSidebar: {
            type: String,
            required: true
        },
        titleOfSidebar: {
            type: String,
            required: true
        },
        descriptionOfSidebar: {
            type: String,
            required: true
        },
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        actionType: {
            type: String,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['closeSidebar', 'createStructureForAttribute', 'deleteStructureForAttribute'],
    data: () => ({
        store: useWorkflowStore(),
        attributesAddNotSavedData: []
    }),
    computed: {
        iconComponent(){
            return iconMapper[this.typeOfSidebar] || IconClipboard
        },
        sidebarMessageNotSaved(){
            return this.store.sidebarMessageNotSaved
        }
    },
    watch: {
        active(newVal){
            this.store.sidebarActive = newVal
        }
    },
    methods: {
        attributesAddNotSaved(data){
            this.attributesAddNotSavedData = data
        },
        closeSidebar(){
            this.$emit('closeSidebar', '');
            setTimeout(() => {
                if(this.attributesAddNotSavedData.length > 0 && this.sidebarMessageNotSaved){
                    this.attributesAddNotSavedData.forEach(id => {
                        this.deleteStructureForAttribute(id)
                    });
                }
                this.store.sidebarMessageNotSaved = false
            },100)
        },
        createStructureForAttribute(){
            this.$emit('createStructureForAttribute', '')
        },
        deleteStructureForAttribute(data){
            this.$emit('deleteStructureForAttribute', data)
        }
    }
}
</script>