<!-- eslint-disable vue/attributes-order -->
<template>
    <div class="container-fluid my-3 EventoTiempoReal-container">
        <div v-for="(data, i) in forms" :key="i" class="EventoTiempoReal-condition mt-3 px-3 pt-3 pb-3">
            <div class="row">
                <div class="col-6">
                    <p class="workflow-management-filters-label">Tipo de evento</p>
                    <select
                        v-model="data.tipoEvento"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option v-for="(item, i2) in ListaTipoEvento" :key="i2" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div class="col-6">
                    <p class="workflow-management-filters-label">Estado de ejecución</p>
                    <select
                        v-model="data.estadoEjecucion"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option v-for="(item, i2) in ListaEstadoEjecucion" :key="i2" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
            </div>
            <div v-for="(conditionItem, index) in data.conditionsArray" :key="index" class="EventoTiempoReal-condition-child mt-3">
                <div v-if="index > 0" class="row">
                    <div class="col-4">
                        <p class="workflow-management-filters-label">Tipo de condición</p>
                        <select
                            v-model="conditionItem.typeOfCondition"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option value="AND">And</option>
                            <option value="OR">Or</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <p class="workflow-management-filters-label">Condicionales</p>
                        <select
                            v-model="conditionItem.condicional"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option v-for="(item, i2) in ListaCondicionales" :key="i2" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <p class="workflow-management-filters-label">Número de veces</p>
                        <input v-model="conditionItem.description" class="form-control" placeholder="Escribir" min="0" step="1">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-8">
                        <p class="workflow-management-filters-label">Periodo de ejecución</p>
                        <select
                            v-model="conditionItem.perdiodoEjecucion"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option v-for="(item, i2) in ListaPeriodoEjecucion" :key="i2" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <p class="workflow-management-filters-label">Número de días</p>
                        <input v-model="conditionItem.dias" type="number" class="form-control" placeholder="Escribir" min="0" step="1">
                    </div>
                </div>
                <div class="row justify-content-between mt-3">
                    <div class="col-auto">
                        <button type="button" class="btn btn-warning m-0" @click="addConditionChild(i)">
                            <div class="d-flex align-items-center gap-1">
                                <IconTablePlus color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                                <span>And / Or</span>
                            </div>
                        </button>
                    </div>
                    <div v-if="index > 0" class="col-auto">
                        <button type="button" class="btn btn-danger mb-0" @click="removeConditionChild(index, i)">
                            <div class="d-flex align-items-center gap-1">
                                <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="i > 0" class="row justify-content-end">
                <div class="col-auto">
                    <button type="button" class="btn btn-danger mb-0 mt-3" @click="removeForm(data.nodeAttributeId)">
                        <div class="d-flex align-items-center gap-1">
                            <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Eliminar</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div>
            <div class="row justify-content-between mt-4">
                <div class="col-auto">
                    <button type="button" class="btn btn-info" @click="newForm">
                        <div class="d-flex align-items-center gap-1">
                            <IconPlaylistAdd color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Agregar condición</span>
                        </div>
                    </button>
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-primary" @click="save">
                        <div class="d-flex align-items-center gap-1">
                            <IconDeviceFloppy color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Grabar configuración</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconPlaylistAdd, IconDeviceFloppy, IconTrash, IconTablePlus } from '@tabler/icons-vue';
import { ListaTipoEvento, ListaEstadoEjecucion, ListaCondicionales, ListaPeriodoEjecucion, SaveConfiguration } from './apiServices';
import { useWorkflowStore } from '../../../store/workflowStore';

export default{
    name: 'EventoTiempoReal',
    components: {
        IconPlaylistAdd,
        IconDeviceFloppy,
        IconTrash,
        IconTablePlus
    },
    props: {
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['createStructureForAttribute', 'deleteStructureForAttribute', 'attributesAddNotSaved'],
    data: () => ({
        store: useWorkflowStore(),
        forms: [],
        ListaTipoEvento: [],
        ListaEstadoEjecucion: [],
        ListaCondicionales: [],
        ListaPeriodoEjecucion: [],
        attributesAddNotSaved: [],
        firstMounted: 0
    }),
    watch: {
        dataForSidebarConfiguration: {
            async handler(newVal){
                if (Object.entries(newVal).length !== 0) {
                    this.forms = newVal.conditions
                }
            },
            deep: true
        },
        forms: {
            handler(){
                if(this.firstMounted >= 1){
                    this.store.sidebarMessageNotSaved = true
                }
            },
            deep: true
        }
    },
    mounted(){
        this.ListaTipoEvento.length === 0 ? this.ObtainListaTipoEvento() : ''
        this.ListaEstadoEjecucion.length === 0 ? this.ObtainListaEstadoEjecucion() : ''
        this.ListaCondicionales.length === 0 ? this.ObtainListaCondicionales() : ''
        this.ListaPeriodoEjecucion.length === 0 ? this.ObtainListaPeriodoEjecucion() : ''
        if(this.firstMounted > 0){
            this.newForm()
        }
    },
    unmounted(){
        if(this.store.sidebarMessageNotSaved){
            this.$emit('attributesAddNotSaved', this.attributesAddNotSaved)
        }
    },
    methods: {
        newForm(){
            this.createStructureForAttribute()
            this.forms.push({
                tipoEvento: null,
                estadoEjecucion: null,
                nodeAttributeId: this.store.lastAttributeCreate,
                conditionsArray: [
                    {
                        typeOfCondition: null,
                        condicional: null,
                        description: '',
                        perdiodoEjecucion: null,
                        dias: 0
                    }
                ]
            })
            this.attributesAddNotSaved.push(this.store.lastAttributeCreate)
            this.firstMounted += 1
        },
        removeForm(id){
            this.forms = this.forms.filter(objeto => objeto.nodeAttributeId !== id);
            this.deleteStructureForAttribute(id)
        },
        addConditionChild(index){
            this.forms[index].conditionsArray.push({
                typeOfCondition: null,
                condicional: null,
                description: '',
                perdiodoEjecucion: null,
                dias: 0
            })
        },
        removeConditionChild(index, i){
            this.forms[i].conditionsArray.splice(index, 1);
        },
        async ObtainListaTipoEvento(){
            this.ListaTipoEvento = await ListaTipoEvento()
        },
        async ObtainListaEstadoEjecucion(){
            this.ListaEstadoEjecucion = await ListaEstadoEjecucion()
        },
        async ObtainListaCondicionales(){
            this.ListaCondicionales = await ListaCondicionales()
        },
        async ObtainListaPeriodoEjecucion(){
            this.ListaPeriodoEjecucion = await ListaPeriodoEjecucion()
        },
        async save(){
            const params = {
                activatorId: this.idNodeType,
                nodeId: this.nodeId,
                conditions: this.forms
            }
            await SaveConfiguration(params)
            this.store.sidebarMessageNotSaved = false
        },
        createStructureForAttribute(){
            this.$emit('createStructureForAttribute', '')
        },
        deleteStructureForAttribute(id){
            this.$emit('deleteStructureForAttribute', id)
        }
    }
}
</script>

<style scoped>
.EventoTiempoReal-container{
    max-height: calc(100% - 100px);
    overflow-y: auto;
}

.EventoTiempoReal-container::-webkit-scrollbar {
    width: 0px;
}

.EventoTiempoReal-condition{
    background-color: #eeeeee;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
}

.EventoTiempoReal-condition-child{
    background-color: #dddcdc;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    padding: 12px 8px;
}
</style>