<template>
    <div class="workflow-actions-icons">
        <div>
            <RouterLink to="/WorkflowPanel" @click="editWorkflow(workflowId)">
                <IconEdit size="16"/>
            </RouterLink>
        </div>
        <div class="position-relative">
            <a to="/WorkflowPanel" @click="toggleModal">
                <IconTrash size="16" color="#fff"/>
            </a>
            <ModalDelete
                :active="modalActive"
                :style="{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }"
                @close="closeModal"
                @delete="continueDelete"
            />
        </div>
    </div>
</template>

<script>
import { IconTrash, IconEdit } from '@tabler/icons-vue';
import { useWorkflowStore } from '../store/workflowStore.js'
import { WorkflowDelete } from '../services/apiServices.js';
import ModalDelete from '../ModalDelete.vue';

export default{
    name: 'WorkflowActionsIcons',
    components: {
        IconTrash,
        IconEdit,
        ModalDelete
    },
    props: {
        workflowId: {
            type: Number,
            required: true
        }
    },
    emits: ['deleteWorkflow'],
    data: () => ({
        modalActive: false,
        modalPosition: {
            top: 0,
            left: 0
        }
    }),
    computed: {
        workflowContigurationState(){
            const store = useWorkflowStore()
            return store.workflowConfigurationData
        }
    },
    watch: {
        workflowContigurationState(data){
            console.log(data)
        }
    },
    methods: {
        async editWorkflow(id){
            const store = useWorkflowStore()
            store.loaderWorkflowPanel = true
            store.workflowId = id
            store.workflowIsCreate = false
            store.workflowPanelNotSaved = false
            store.typeOfWorkflowPanel = 'editar'
            await store.setWorkflowConfigurationData(id)
            store.goToTemplates = false
        },
        async deleteWorkflow(id){
            const params = {
                workflowId: id
            }
            await WorkflowDelete(params)
            this.$emit('deleteWorkflow')
            const store = useWorkflowStore()
            store.goToTemplates = false
        },
        closeModal(){
            this.modalActive = false
        },
        continueDelete(){
            this.deleteWorkflow(this.workflowId)
            this.modalActive = false
        },
        toggleModal(event){
            this.modalActive ? this.modalActive = false : this.modalActive = true
            if (this.modalActive) {
                const positionRelative = event.currentTarget;
                const rect = positionRelative.getBoundingClientRect();
                this.modalPosition = {
                top: rect.top - 8,
                left: rect.left - 190,
                };
            }
        }
    }
}
</script>