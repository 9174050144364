<!-- eslint-disable vue/attributes-order -->
<template>
    <div class="container-fluid my-3 EventoTiempoReal-container">
        <div v-for="(data, i) in forms" :key="i" class="EventoTiempoReal-condition mt-3 px-3 pt-3 pb-3">
            <div class="row">
                <div class="col-6">
                    <p class="workflow-management-filters-label">Opciones</p>
                    <select
                        v-model="data.event"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option v-for="(item, i2) in ListaTipoEvento" :key="i2" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div class="col-6">
                    <p class="workflow-management-filters-label">Condición</p>
                    <select
                        v-model="data.option"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option v-for="(item, i2) in ListaEstadoEjecucion" :key="i2" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div class="col-12 mt-3">
                    <p class="workflow-management-filters-label">Texto para analizar</p>
                    <textarea v-model="data.optionDescriptor" class="form-control" style="min-height: 100px"></textarea>
                </div>
            </div>
            <div v-if="i > 0" class="row justify-content-end">
                <div class="col-auto">
                    <button type="button" class="btn btn-danger mb-0 mt-3" @click="removeForm(data.nodeAttributeId)">
                        <div class="d-flex align-items-center gap-1">
                            <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Eliminar</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div>
            <div class="row justify-content-between mt-4">
                <div class="col-auto">
                    <button type="button" class="btn btn-info" @click="newForm">
                        <div class="d-flex align-items-center gap-1">
                            <IconPlaylistAdd color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Agregar condición</span>
                        </div>
                    </button>
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-primary" @click="save">
                        <div class="d-flex align-items-center gap-1">
                            <IconDeviceFloppy color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Grabar configuración</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconPlaylistAdd, IconDeviceFloppy, IconTrash } from '@tabler/icons-vue';
import { ListaOpciones, SaveConfiguration } from './apiServices';
import { useWorkflowStore } from '../../../store/workflowStore';

export default{
    name: 'EventoTiempoReal',
    components: {
        IconPlaylistAdd,
        IconDeviceFloppy,
        IconTrash,
    },
    props: {
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['createStructureForAttribute', 'deleteStructureForAttribute', 'attributesAddNotSaved'],
    data: () => ({
        store: useWorkflowStore(),
        forms: [],
        ListaTipoEvento: [],
        ListaEstadoEjecucion: [],
        ListaOpciones: [],
        ListaDuranteLosUltimos: [],
        attributesAddNotSaved: [],
        firstMounted: 0
    }),
    watch: {
        dataForSidebarConfiguration: {
            async handler(newVal){
                if (Object.entries(newVal).length !== 0) {
                    this.forms = newVal.conditions
                }
            },
            deep: true
        },
        forms: {
            handler(){
                if(this.firstMounted >= 1){
                    this.store.sidebarMessageNotSaved = true
                }
            },
            deep: true
        }
    },
    mounted(){
        this.ListaOpciones.length === 0 ? this.ObtainListaOpciones() : ''
        if(this.firstMounted > 0){
            this.newForm()
        }
    },
    unmounted(){
        if(this.store.sidebarMessageNotSaved){
            this.$emit('attributesAddNotSaved', this.attributesAddNotSaved)
        }
    },
    methods: {
        newForm(){
            this.createStructureForAttribute()
            this.forms.push({
                attribute: undefined,
                nodeAttributeId: this.store.lastAttributeCreate,
                event: undefined,
                option: undefined,
                optionType: undefined,
                optionDescriptor: undefined,
                conditionTime: undefined,
            })
            this.attributesAddNotSaved.push(this.store.lastAttributeCreate)
            this.firstMounted += 1
        },
        removeForm(id){
            this.forms = this.forms.filter(objeto => objeto.nodeAttributeId !== id);
            this.deleteStructureForAttribute(id)
        },
        async ObtainListaOpciones(){
            this.ListaOpciones = await ListaOpciones()
        },
        async save(){
            const params = {
                activatorId: this.idNodeType,
                nodeId: this.nodeId,
                conditions: this.forms
            }
            await SaveConfiguration(params)
            this.store.sidebarMessageNotSaved = false
        },
        createStructureForAttribute(){
            this.$emit('createStructureForAttribute', '')
        },
        deleteStructureForAttribute(id){
            this.$emit('deleteStructureForAttribute', id)
        }
    }
}
</script>

<style scoped>
.EventoTiempoReal-container{
    max-height: calc(100% - 100px);
    overflow-y: auto;
}

.EventoTiempoReal-container::-webkit-scrollbar {
    width: 0px;
}

.EventoTiempoReal-condition{
    background-color: #eeeeee;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
}

.EventoTiempoReal-condition-child{
    background-color: #dddcdc;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    padding: 12px 8px;
}
</style>