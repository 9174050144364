import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaAtributo: 'action/configuration/sidebard/headers/available',
    listaAtributoCondicion: 'configuration/sidebard/audiencesegmentation/dropdown/1/available',
    listaComportamientoEvento: 'configuration/sidebard/audiencesegmentation/dropdown/2/available',
    listaComportamientoCondicion: 'configuration/sidebard/audiencesegmentation/dropdown/3/available',
    listaComportamientoComparacion: 'configuration/sidebard/audiencesegmentation/dropdown/4/available',
    listaComportamientoRangoTiempo: 'configuration/sidebard/audiencesegmentation/dropdown/5/available',
    listaComportamientoUnidadTiempo: 'configuration/sidebard/audiencesegmentation/dropdown/6/available',
    save: 'action/configuration/sidebard/audiencesegmentation/add',
}

export async function ListaAtributo() {
    try {
        const response = await postRequest(apiUrls.listaAtributo, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaAtributoCondicion() {
    try {
        const response = await postRequest(apiUrls.listaAtributoCondicion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaComportamientoEvento() {
    try {
        const response = await postRequest(apiUrls.listaComportamientoEvento, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaComportamientoCondicion() {
    try {
        const response = await postRequest(apiUrls.listaComportamientoCondicion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaComportamientoComparacion() {
    try {
        const response = await postRequest(apiUrls.listaComportamientoComparacion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaComportamientoRangoTiempo() {
    try {
        const response = await postRequest(apiUrls.listaComportamientoRangoTiempo, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaComportamientoUnidadTiempo() {
    try {
        const response = await postRequest(apiUrls.listaComportamientoUnidadTiempo, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            actionId: params.actionId,
            nodeId: params.nodeId,
            actionType: params.actionType,
            segments: params.segments,
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}