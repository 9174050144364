import { postRequest } from './axiosConfig';
import apiUrls from './apiUrls';
import { toast } from 'vue3-toastify';
import { getUserRealTime } from '../../../config/servicios/campana/util';
import { useWorkflowStore } from '../store/workflowStore';


/* === GENERAL === */
export async function CustomerId(){
    const data = await getUserRealTime()
    return data.customer.customerId
}
/* === END GENERAL === */



/* === WORKFLOW PANEL === */
export async function ActivatorList() {
    try {
        const response = await postRequest(apiUrls.activatorList, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ActionList() {
    try {
        const response = await postRequest(apiUrls.actionList, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}
/* === END WORKFLOW PANEL === */



/* === WORKFLOW MANAGEMENT === */
export async function CustomerList() {
    try {
        const response = await postRequest(apiUrls.customerList, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function StateList() {
    try {
        const response = await postRequest(apiUrls.stateList, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}
/* === END WORKFLOW MANAGEMENT === */



/* === CREATE WORKFLOWS & TEMPLATES === */
export async function CreateWorkflowAndTemplate(params) {
    const store = useWorkflowStore()
    const requestData = {
        request: JSON.stringify({
            workflowId: params.workflowId,
            workflowName: params.workflowName,
            customerId: params.customerId,
            isTemplate: params.isTemplate,
            nodes: params.nodes,
            edges: params.edges
        })
    };
    try {
        const response = await postRequest(apiUrls.createWorkflowAndTemplate, requestData);
        toast("Su Workflow ha sido creado correctamente.", {
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        if(params.workflowId === 0){
            store.workflowId = response.data.workflowId
        }
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}
/* === END CREATE WORKFLOWS & TEMPLATES === */



/* === WORKFLOWS === */
export async function WorkflowList(params) {
    const requestData = {
        request: JSON.stringify({
        customerId: params.customerId,
        page: params.page ? params.page : 1,
        size: params.size ? params.size : 5,
        filterWorkflowName: params.filterWorkflowName ? params.filterWorkflowName : '',
        filterWorkflowState: params.filterWorkflowState ? params.filterWorkflowState : 0,
        filterWorkflowUserCreate: params.filterWorkflowUserCreate ? params.filterWorkflowUserCreate : 0,
        filterWorkflowCreateDate: params.filterWorkflowCreateDate ? params.filterWorkflowCreateDate : ''
        })
    };
    try {
        const response = await postRequest(apiUrls.workflowList, requestData);
        return response.data;
    } catch (error) {
        toast("Wow so easy !", {
            autoClose: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            toastClassName	: 'prueba'
          });
        console.error(error.message);
    }
}

export async function WorkflowConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
        workflowId: params.workflowId
        })
    };
    try {
        const response = await postRequest(apiUrls.workflowConfiguration, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function WorkflowUpdate(params) {
    const requestData = {
        request: JSON.stringify({
            workflowId: params.workflowId,
            workflowName: params.workflowName,
            customerId: params.customerId,
            isTemplate: params.isTemplate,
            nodes: params.nodes,
            edges: params.edges
        })
    };
    try {
        const response = await postRequest(apiUrls.workflowUpdate, requestData);
        toast("Su Workflow ha sido actualizado correctamente.", {
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function WorkflowDelete(params) {
    const requestData = {
        request: JSON.stringify({
            workflowId: params.workflowId
        })
    };
    try {
        const response = await postRequest(apiUrls.workflowDelete, requestData);
        return response.data;
    } catch (error) {
        console.error(error.message);
    }
}
/* === END WORKFLOWS === */



/* === TEMPLATES === */
export async function TemplateList(params) {
    const requestData = {
        request: JSON.stringify({
        customerId: params.customerId != 0 ? params.customerId : 0
        })
    };
    try {
        const response = await postRequest(apiUrls.templateList, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function TemplateConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            workflowId: params.workflowId
        })
    };
    try {
        const response = await postRequest(apiUrls.templateConfiguration, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function TemplateDelete(params) {
    const requestData = {
        request: JSON.stringify({
            workflowId: params.workflowId
        })
    };
    try {
        const response = await postRequest(apiUrls.templateDelete, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function TemplateUpdate(params) {
    const requestData = {
        request: JSON.stringify({
            workflowId: params.workflowId,
            workflowName: params.workflowName,
            customerId: params.customerId,
            isTemplate: params.isTemplate,
            nodes: params.nodes,
            edges: params.edges
        })
    };
    try {
        const response = await postRequest(apiUrls.templateUpdate, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}
/* === END TEMPLATES === */