import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaTipoDePausa: 'configuration/sidebard/pause/dropdown/1/available',
    save: 'action/configuration/sidebard/pause/add',
}

export async function ListaTipoDePausa() {
    try {
        const response = await postRequest(apiUrls.listaTipoDePausa, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            actionId: params.actionId,
            nodeId: params.nodeId,
            actionType: params.actionType,
            url: params.url,
            option: params.option,
            hour: params.hour,
            useDaysOfWeek: params.useDaysOfWeek,
            daysOfWeekValue: params.daysOfWeekValue,
            timeUnit: params.timeUnit,
            timeUnitValue: params.timeUnitValue,
            dateOfOption: params.dateOfOption,
            nodeAttributeId: 0
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}