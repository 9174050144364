<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<!-- eslint-disable vue/attributes-order -->
<template>
    <div class="container-fluid my-3 MensajeInbound-container">
        <div class="row">
            <div class="col">
                <p class="workflow-management-filters-label">Canales</p>
                <div class="workflow-panel-menu-options-radio-buttons">
                    <label class="workflow-input-radio">
                        <input v-model="channels" type="radio" :value="1">
                        <span class="checkmark"></span>
                        <p>SMS</p>
                    </label>
                    <label class="workflow-input-radio">
                        <input v-model="channels" type="radio" :value="2">
                        <span class="checkmark"></span>
                        <p>Whatsapp</p>
                    </label>
                    <label class="workflow-input-radio">
                        <input v-model="channels" type="radio" :value="3">
                        <span class="checkmark"></span>
                        <p>Email</p>
                    </label>
                </div>
                <div v-if="channels === null" class="mt-3">
                    <p class="font-14 text-danger">Seleccione un tipo de canal para poder continuar.</p>
                </div>
                <div v-if="forms.length === 0 && channels !== null" class="mt-3">
                    <p class="font-14 text-danger">Para poder grabar la configuración debe de existir por lo menos un formulario de condición.</p>
                </div>
            </div>
        </div>
        <div v-if="channels !== null">
            <div v-for="(data, i) in forms" :key="i" class="MensajeInbound-condition mt-3 px-3 pt-3 pb-3">
                <transition name="WorkflowNewActivatorFade">
                    <LoaderComponent v-if="loaderForm"/>
                </transition>
                <div class="row">
                    <div class="col-6">
                        <p class="workflow-management-filters-label">Al recipiente</p>
                        <select
                            v-model="data.recipientId"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option v-if="channels === 1" v-for="(contact, i2) in contactSelectedData" :key="i2" :value="contact.senderMsisdnId">{{ contact.msisdn }}</option>
                            <option v-if="channels === 2" v-for="(contact, i2) in contactSelectedData" :key="i2" :value="contact.customerWhatsappId">{{ contact.whatsappSrc }}</option>
                            <option v-if="channels === 3" v-for="(contact, i2) in contactSelectedData" :key="i2" :value="contact.emailSenderId">{{ contact.emailFrom }}</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <p class="workflow-management-filters-label">Analizar</p>
                        <select
                            v-model="data.conditionId"
                            class="multisteps-form__select form-control form-select"
                        >
                        <option v-for="(analyze, i3) in analyzeSelectedData" :key="i3" :value="analyze.id">{{ analyze.name }}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <p class="workflow-management-filters-label">Regla de condicion</p>
                        <select
                            v-model="data.selectionId"
                            class="multisteps-form__select form-control form-select"
                        >
                        <option v-for="(condition, i4) in conditionSelectedData" :key="i4" :value="condition.id">{{ condition.name }}</option>
                        </select>
                    </div>
                    <div class="col-12 mt-3">
                        <p class="workflow-management-filters-label">Texto que desea analizar</p>
                        <input v-model="data.description" type="text" class="form-control" placeholder="Escribir">
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-auto">
                        <button type="button" class="btn btn-danger mb-0 mt-3" @click="removeForm(data.nodeAttributeId)">
                            <div class="d-flex align-items-center gap-1">
                                <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                                <span>Eliminar</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row justify-content-between mt-4">
                <div class="col-auto">
                    <button type="button" class="btn btn-info" @click="newForm">
                        <div class="d-flex align-items-center gap-1">
                            <IconPlaylistAdd color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Agregar condición</span>
                        </div>
                    </button>
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-primary" @click="save" :disabled="stateButtonSave">
                        <div class="d-flex align-items-center gap-1">
                            <IconDeviceFloppy color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Grabar configuración</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconPlaylistAdd, IconDeviceFloppy, IconTrash } from '@tabler/icons-vue';
import { ListaSenders, ListaCondicion, ListaSeleccion, SaveConfiguration } from './apiServices';
import LoaderComponent from '../../../LoaderComponent.vue';
import { useWorkflowStore } from '../../../store/workflowStore';

export default{
    name: 'MensajeInbound',
    components: {
        IconPlaylistAdd,
        IconDeviceFloppy,
        IconTrash,
        LoaderComponent
    },
    props: {
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['createStructureForAttribute', 'deleteStructureForAttribute', 'attributesAddNotSaved'],
    data: () => ({
        channels: null,
        forms: [],
        contactSelectedData: [],
        analyzeSelectedData: [],
        conditionSelectedData: [],
        loaderForm: false,
        store: useWorkflowStore(),
        stateButtonSave: true,
        firstMounted: 0,
        attributesAddNotSaved: [],
        attributesListForDelete: []
    }),
    computed: {
        sidebarActive(){
            return this.store.sidebarActive
        }
    },
    watch: {
        async channels(){
            this.loaderForm = true
            if(this.firstMounted >= 1){
                this.attributesListForDelete = this.forms.map(form => form.nodeAttributeId)
                this.forms = []
                this.store.sidebarMessageNotSaved = true
            }
            if(this.forms.length === 0){
                this.newForm()
            }
            this.contactSelectedData = await ListaSenders({channelId: this.channels})
            if(this.analyzeSelectedData.length === 0){
                this.analyzeSelectedData = await ListaCondicion()
            }
            if(this.conditionSelectedData.length === 0){
                this.conditionSelectedData = await ListaSeleccion()
            }
            this.loaderForm = false
            this.firstMounted += 1
            this.disabledButtonSave()
        },
        dataForSidebarConfiguration: {
            async handler(newVal){
                if (Object.entries(newVal).length !== 0) {
                    this.channels = parseInt(newVal.channel)
                    this.forms = newVal.conditions
                }
                this.disabledButtonSave()
            },
            deep: true
        },
        forms: {
            handler(){
                if(this.firstMounted >= 1){
                    this.store.sidebarMessageNotSaved = true
                }
                this.disabledButtonSave()
            },
            deep: true
        }
    },
    mounted(){
        this.disabledButtonSave()
    },
    unmounted(){
        if(this.store.sidebarMessageNotSaved){
            this.$emit('attributesAddNotSaved', this.attributesAddNotSaved)
        }
    },
    methods: {
        newForm(){
            this.createStructureForAttribute()
            this.forms.push({
                recipientId: null,
                conditionId: null,
                selectionId: null,
                description: '',
                nodeAttributeId: this.store.lastAttributeCreate
            })
            this.attributesAddNotSaved.push(this.store.lastAttributeCreate)
        },
        removeForm(id){
            this.forms = this.forms.filter(objeto => objeto.nodeAttributeId !== id);
            this.deleteStructureForAttribute(id)
        },
        async save(){
            if(this.attributesListForDelete.length > 0){
                this.attributesListForDelete.forEach(id => {
                    this.deleteStructureForAttribute(id);
                })
            }
            const params = {
                activatorId: this.idNodeType,
                nodeId: this.nodeId,
                channel: this.channels,
                conditions: this.forms
            }
            await SaveConfiguration(params)
            this.store.sidebarMessageNotSaved = false
        },
        createStructureForAttribute(){
            this.$emit('createStructureForAttribute', '')
        },
        deleteStructureForAttribute(id){
            this.$emit('deleteStructureForAttribute', id)
        },
        validateForms(array){
            return array.every(form => 
                form.recipientId !== null &&
                form.conditionId !== null &&
                form.selectionId !== null
            );
        },
        disabledButtonSave(){
            this.validateForms(this.forms) ? this.stateButtonSave = false : this.stateButtonSave = true
        }
    }
}
</script>

<style>
.MensajeInbound-container{
    max-height: calc(100% - 100px);
    overflow-y: auto;
}

.MensajeInbound-container::-webkit-scrollbar {
    width: 0px;
}

.MensajeInbound-condition{
    background-color: #eeeeee;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    position: relative;
    overflow: hidden;
}
</style>