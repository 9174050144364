import { postRequest } from './axiosConfig';

const apiUrls = {
    /* ACTIVATORS */
    audienciaPredefinida: 'configuration/sidebard/apf/available',
    mensajeInbound: 'configuration/sidebard/me/available',
    fechaHora: 'configuration/sidebard/fh/available',
    cambioPerfil: 'configuration/sidebard/cp/available',
    eventoTiempoReal: 'configuration/sidebard/etr/available',
    /* ACTOINS */
    enviarSms: 'action/configuration/sidebard/sms/available',
    enviarEmail: 'action/configuration/sidebard/email/available',
    enviarWhatsapp: 'action/configuration/sidebard/whatsapp/available',
    /* FUNCTIONS */
    agregarTag: 'action/configuration/sidebard/tag/available',
    deleteTag: 'action/configuration/sidebard/deletetag/available',
    llamadaApi: 'action/configuration/sidebard/callapi/available',
    segmentarAudiencia: 'action/configuration/sidebard/audiencesegmentation/available',
    pausar: 'action/configuration/sidebard/pause/available',
    esperarEvento: 'action/configuration/sidebard/eue/available'
}

export async function ObtainAudienciaPredefinida(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.audienciaPredefinida, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainMensajeInbound(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.mensajeInbound, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainFechaHora(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.fechaHora, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainCambioPerfil(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.cambioPerfil, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainEventoTiempoReal(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.eventoTiempoReal, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainEnviarSms(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.enviarSms, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainEnviarEmail(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.enviarEmail, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainEnviarWhatsapp(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.enviarWhatsapp, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainAgregarTag(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.agregarTag, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainBorrarTag(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.deleteTag, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainLlamadaApi(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.llamadaApi, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainSegmentarAudiencia(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.segmentarAudiencia, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainPausar(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.pausar, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ObtainEsperarEvento(params) {
    const requestData = {
        request: JSON.stringify({
            nodeId: params.nodeId
        })
    };
    try {
        const response = await postRequest(apiUrls.esperarEvento, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}