export default {
  activatorList: 'activator/available',
  actionList: 'action/available',
  customerList: 'customer/available',
  stateList: 'status/available',
  createWorkflowAndTemplate: 'configuration/add',
  workflowList: 'available',
  workflowConfiguration: 'configuration/available',
  workflowUpdate: 'configuration/edit',
  workflowDelete: 'configuration/delete',
  templateList: 'template/available',
  templateConfiguration: 'template/configuration/available',
  templateDelete: 'template/delete',
  templateUpdate: 'template/configuration/add'
};
  