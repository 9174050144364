<template>
    <div class="flatpickr-input-box">
        <input ref="timePicker" v-model="initialInput" placeholder="Seleccionar"/>
        <button @click="clearTime">
            <IconX size="14" stroke-width="2.5"/>
        </button>
    </div>
</template>

<script>
import { IconX } from '@tabler/icons-vue';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

export default {
    name: 'WorkflowTimePicker',
    components: {
        IconX
    },
    props: {
        initialData: {
            type: String,
            default: ''
        }
    },
    emits: ['changeTime', 'clearTime'],
    data() {
        return {
            flatpickrInstance: null,
            initialInput: ''
        };
    },
    watch: {
        initialData: {
            handler(newVal) {
                this.initialInput = newVal;
            },
            immediate: true
        }
    },
    mounted() {
        this.flatpickrInstance = flatpickr(this.$refs.timePicker, {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            time_24hr: true,
            onChange: (selectedDates) => {
                if (selectedDates.length > 0) {
                    const selectedTime = selectedDates[0];
                    const formattedTime = selectedTime.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
                    this.$emit('changeTime', formattedTime);
                }
            },
        });
    },
    beforeUnmount() {
        if (this.flatpickrInstance) {
            this.flatpickrInstance.destroy();
        }
    },
    methods: {
        clearTime() {
            this.flatpickrInstance.clear();
            this.$emit('clearTime', '');
        }
    }
}
</script>

<style scoped>
.flatpickr-input-box {
    position: relative;
}

.flatpickr-input-box button {
    position: absolute;
    top: 49%;
    transform: translateY(-50%);
    right: 4px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #8e8f8f;
}

.flatpickr-input-box button:hover {
    color: #C55845;
}

.flatpickr-input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #4f575f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d6da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
    outline: none;
}

.flatpickr-input:focus {
    border: 1px solid #0033A0;
}

.flatpickr-input::placeholder{
    font-size: 12px;
}
</style>