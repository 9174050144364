<!-- eslint-disable vue/attributes-order -->
<template>
    <div class="container-fluid my-3 FechaHora-container">
        <div class="row">
            <div class="col-6">
                <p class="workflow-management-filters-label">Buscar en</p>
                <select
                    v-model="searchIn"
                    class="multisteps-form__select form-control form-select"
                >
                    <option v-for="(data, i) in searchInOptions" :key="i" :value="data.id">{{ data.name }}</option>
                </select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-5">
                <p class="workflow-management-filters-label">Condición</p>
                <select
                    v-model="conditionType"
                    class="multisteps-form__select form-control form-select"
                >
                    <option v-for="(data, i) in conditionTypeOptions" :key="i" :value="data.id">{{ data.name }}</option>
                </select>
            </div>
            <div class="col-2">
                <p class="workflow-management-filters-label">Cantidad</p>
                <input v-model="count" type="number" class="form-control" placeholder="Escribir" min="0" step="1">
            </div>
            <div class="col-5">
                <p class="workflow-management-filters-label">Días / Horas</p>
                <select
                    v-model="countType"
                    class="multisteps-form__select form-control form-select"
                >
                    <option v-for="(data, i) in countTypeOptions" :key="i" :value="data.id">{{ data.name }}</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-end mt-4">
            <div class="col-auto">
                <button type="button" class="btn btn-primary" @click="Save">
                    <div class="d-flex align-items-center gap-1">
                        <IconDeviceFloppy color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                        <span>Grabar configuración</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { IconDeviceFloppy } from '@tabler/icons-vue';
import { ListaBuscarEn, ListaCondicion, ListaDiasHoras, SaveConfiguration } from './apiServices';

export default{
    name: 'FechaHora',
    components: {
        IconDeviceFloppy
    },
    props: {
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        searchIn: null,
        searchInOptions: [],
        conditionType: null,
        conditionTypeOptions: [],
        count: 0,
        countType: null,
        countTypeOptions: []
    }),
    watch: {
        dataForSidebarConfiguration: {
            handler(newVal){
                this.searchIn = newVal.dropdown1Id
                this.conditionType = newVal.dropdown2Id
                this.count = newVal.days
                this.countType = newVal.dropdown3Id
            },
            deep: true
        }
    },
    async mounted(){
        this.searchInOptions = await ListaBuscarEn()
        this.conditionTypeOptions = await ListaCondicion()
        this.countTypeOptions = await ListaDiasHoras()
    },
    methods: {
        async Save(){
            const params = {
                activatorId: this.idNodeType,
                nodeId: this.nodeId,
                dropdown1Id:  this.searchIn,
                dropdown2Id: this.conditionType,
                dropdown3Id: this.countType,
                days: this.count
            }
            await SaveConfiguration(params)
        }
    }
}
</script>

<style scoped>
.FechaHora-container{
    max-height: calc(100% - 50px);
    overflow-y: auto;
}

.FechaHora-container::-webkit-scrollbar {
    width: 0px;
}

.FechaHora-condition{
    background-color: #eeeeee;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
}

.FechaHora-condition-child{
    background-color: #dddcdc;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    padding: 12px 8px;
}
</style>