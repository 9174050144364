<template>
    <div class="workflow-table-pagination">
      <button :disabled="currentPage === 1" @click="prevPage">
        <IconChevronLeft size="16"/>
      </button>
      <button
        v-for="page in visiblePages"
        :key="page"
        :class="{ active: page === currentPage }"
        @click="goToPage(page)"
      >
        <span>{{ page }}</span>
      </button>
      <button :disabled="currentPage === totalPages" @click="nextPage">
        <IconChevronRight size="16"/>
      </button>
    </div>
  </template>
  
  <script>
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-vue';

  export default {
    name: 'WorkflowTablePagination',
    components: {
      IconChevronLeft,
      IconChevronRight
    },
    props: {
      totalItems: {
        type: Number,
        required: true,
      },
      itemsPerPage: {
        type: Number,
        default: 10,
      },
      updateRecordAmmount: {
        type: Boolean
      }
    },
    emits: ['changePage'],
    data() {
      return {
        currentPage: 1,
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.itemsPerPage);
      },
      // Calcula las páginas visibles según la página actual
      visiblePages() {
        const pages = [];
        const maxVisiblePages = 5;
  
        let startPage = Math.max(1, this.currentPage - 2);
        let endPage = Math.min(this.totalPages, this.currentPage + 2);
  
        // Ajuste si estamos al principio o al final
        if (this.currentPage <= 3) {
          endPage = Math.min(this.totalPages, maxVisiblePages);
        } else if (this.currentPage >= this.totalPages - 2) {
          startPage = Math.max(1, this.totalPages - maxVisiblePages + 1);
        }
  
        // Rellenar las páginas visibles
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
  
        // Agregar truncamiento ("...") si es necesario
        if (startPage > 1) pages.unshift('...');
        if (endPage < this.totalPages) pages.push('...');
  
        return pages;
      },
    },
    watch: {
      currentPage(data){
        this.$emit('changePage', data)
      },
      updateRecordAmmount(){
        this.currentPage = 1
      }
    },
    methods: {
      goToPage(page) {
        if (page === '...') return; // Ignora los botones de truncamiento
        this.currentPage = page;
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage += 1;
        }
      },
    },
  };
  </script>
  