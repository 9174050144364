<!-- eslint-disable vue/attributes-order -->
<template>
    <div class="container-fluid my-3 ImportacionSFTP-container">
        <div v-for="(data, i) in forms" :key="i" class="ImportacionSFTP-condition mt-3 px-3 pt-3 pb-3">
            <div class="row">
                <div class="col-6">
                    <p class="workflow-management-filters-label">Tipo de evento</p>
                    <select
                        v-model="data.eventType"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option :value="1">Opcion</option>
                    </select>
                </div>
                <div class="col-6">
                    <p class="workflow-management-filters-label">Estado de ejecución</p>
                    <select
                        v-model="data.executionState"
                        class="multisteps-form__select form-control form-select"
                    >
                        <option :value="1">Ocurrió</option>
                        <option :value="2">No ocurrió</option>
                    </select>
                </div>
            </div>
            <div v-for="(conditionItem, index) in data.conditionsArray" :key="index" class="ImportacionSFTP-condition-child mt-3">
                <div v-if="index > 0" class="row">
                    <div class="col-4">
                        <p class="workflow-management-filters-label">Tipo de condición</p>
                        <select
                            v-model="conditionItem.typeOfCondition"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option :value="1">And</option>
                            <option :value="2">Or</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-8">
                        <p class="workflow-management-filters-label">Condicionales</p>
                        <select
                            v-model="conditionItem.conditionalOne"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option :value="1">Igual</option>
                            <option :value="2">Mayor que</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <p class="workflow-management-filters-label">Número de veces</p>
                        <input v-model="conditionItem.countConditionalOne" type="number" class="form-control" placeholder="Escribir" min="0" step="1">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-8">
                        <p class="workflow-management-filters-label">Periodo de ejecución</p>
                        <select
                            v-model="conditionItem.conditionalTwo"
                            class="multisteps-form__select form-control form-select"
                        >
                            <option :value="1">Igual</option>
                            <option :value="2">Mayor que</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <p class="workflow-management-filters-label">Número de días</p>
                        <input v-model="conditionItem.countConditionalTwo" type="number" class="form-control" placeholder="Escribir" min="0" step="1">
                    </div>
                </div>
                <div class="row justify-content-between mt-3">
                    <div class="col-auto">
                        <button type="button" class="btn btn-warning m-0" @click="addConditionChild(i)">
                            <div class="d-flex align-items-center gap-1">
                                <IconTablePlus color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                                <span>And / Or</span>
                            </div>
                        </button>
                    </div>
                    <div v-if="index > 0" class="col-auto">
                        <button type="button" class="btn btn-danger mb-0" @click="removeConditionChild(index, i)">
                            <div class="d-flex align-items-center gap-1">
                                <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                                <!-- <span>Eliminar</span> -->
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="i > 0" class="row justify-content-end">
                <div class="col-auto">
                    <button type="button" class="btn btn-danger mb-0 mt-3" @click="removeForm(i)">
                        <div class="d-flex align-items-center gap-1">
                            <IconTrash color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Eliminar</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div>
            <div class="row justify-content-between mt-4">
                <div class="col-auto">
                    <button type="button" class="btn btn-info" @click="newForm">
                        <div class="d-flex align-items-center gap-1">
                            <IconPlaylistAdd color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Agregar condición</span>
                        </div>
                    </button>
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-primary">
                        <div class="d-flex align-items-center gap-1">
                            <IconDeviceFloppy color="#fff" size="20" stroke-width="2" style="margin-top: 1px;"/>
                            <span>Grabar configuración</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconPlaylistAdd, IconDeviceFloppy, IconTrash, IconTablePlus } from '@tabler/icons-vue';

export default{
    name: 'ImportacionSFTP',
    components: {
        IconPlaylistAdd,
        IconDeviceFloppy,
        IconTrash,
        IconTablePlus
    },
    data: () => ({
        channels: null,
        forms: [
            {
                eventType: null,
                executionState: null,
                conditionsArray: [
                    {
                        typeOfCondition: null,
                        conditionalOne: null,
                        countConditionalOne: 0,
                        conditionalTwo: null,
                        countConditionalTwo: 0
                    }
                ]
            }
        ]
    }),
    methods: {
        newForm(){
            this.forms.push({
                eventType: null,
                executionState: null,
                conditionsArray: [
                    {
                        typeOfCondition: null,
                        conditionalOne: null,
                        countConditionalOne: 0,
                        conditionalTwo: null,
                        countConditionalTwo: 0
                    }
                ]
            })
        },
        removeForm(index){
            this.forms.splice(index, 1);
        },
        addConditionChild(index){
            this.forms[index].conditionsArray.push({
                typeOfCondition: null,
                conditionalOne: null,
                countConditionalOne: 0,
                conditionalTwo: null,
                countConditionalTwo: 0
            })
        },
        removeConditionChild(index, i){
            this.forms[i].conditionsArray.splice(index, 1);
        },
    }
}
</script>

<style scoped>
.ImportacionSFTP-container{
    max-height: calc(100% - 50px);
    overflow-y: auto;
}

.ImportacionSFTP-container::-webkit-scrollbar {
    width: 0px;
}

.ImportacionSFTP-condition{
    background-color: #eeeeee;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
}

.ImportacionSFTP-condition-child{
    background-color: #dddcdc;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    padding: 12px 8px;
}
</style>