import { IconUsers, IconUserScan, IconClockPlay, IconMessageUp, IconCalendarTime, IconHierarchy2, IconApi } from '@tabler/icons-vue';

export const iconMapper = {
  AudienciaPredefinida: IconUsers,
  CambioPerfil: IconUserScan,
  EventoTiempoReal: IconClockPlay,
  MensajeInbound: IconMessageUp,
  FechaHora: IconCalendarTime,
  WorkflowExistente: IconHierarchy2,
  Api: IconApi
};
