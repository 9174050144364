import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaOpciones: 'configuration/sidebard/msjinbound/dropdown/1/available',
    listaCondiciones: 'configuration/sidebard/msjinbound/dropdown/2/available',
    save: 'action/configuration/sidebard/msjinbound/add'
}

export async function ListaOpciones() {
    try {
        const response = await postRequest(apiUrls.listaOpciones, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaCondiciones() {
    try {
        const response = await postRequest(apiUrls.listaCondiciones, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            actionId: params.actionId,
            nodeId: params.nodeId,
            actionType: params.actionType,
            isIndefinite: params.isIndefinite,
            unitTime: params.unitTime,
            unitTimeValue: params.unitTimeValue,
            conditions: params.conditions,
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}