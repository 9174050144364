<!-- eslint-disable vue/attributes-order -->
<template>
    <div class="container-fluid py-3">
        <div class="row">
            <div class="col-8">
                <p class="workflow-management-filters-label">Actualizar audiencia después del lanzamiento</p>
                <select
                    v-model="audienceUpdate"
                    class="multisteps-form__select form-control form-select"
                >
                    <option :value="false">Nunca</option>
                    <option :value="true">En tiempo real</option>
                </select>
            </div>
            <div class="col-12 mt-3">
                <p class="font-14 m-0">Los contactos agupados en listas, segmentos o tag entraran en este flujo.</p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <p class="workflow-management-filters-label">Para</p>
                <div class="workflow-panel-menu-options-radio-buttons">
                    <label class="workflow-input-radio">
                        <input v-model="typeFor" type="radio" value="CONTACTGROUP">
                        <span class="checkmark"></span>
                        <p>Lista</p>
                    </label>
                    <label class="workflow-input-radio">
                        <input v-model="typeFor" type="radio" value="SEGMENT">
                        <span class="checkmark"></span>
                        <p>Segmento</p>
                    </label>
                    <label class="workflow-input-radio">
                        <input v-model="typeFor" type="radio" value="TAG">
                        <span class="checkmark"></span>
                        <p>Tag</p>
                    </label>
                </div>
            </div>
            <div class="col-8 mt-3">
                <p class="workflow-management-filters-label">Escoge tu audiencia</p>
                <select
                    v-if="typeFor !== 'CONTACTGROUP'"
                    v-model="audienceSelected"
                    class="multisteps-form__select form-control form-select"
                    :disabled="audienceSelectedData === null"
                >
                    <option v-for="(data, i) in audienceSelectedData" :key="i"  :value="data.segmentTagId">{{ data.segmentTagName }}</option>
                </select>
                <select
                    v-if="typeFor === 'CONTACTGROUP'"
                    v-model="audienceSelected"
                    class="multisteps-form__select form-control form-select"
                    :disabled="audienceSelectedData === null"
                >
                    <option v-for="(data, i) in audienceSelectedData" :key="i"  :value="data.contactGroupId">{{ data.name }}</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-end mt-4">
            <div class="col-auto">
                <button type="button" class="btn btn-primary" :disabled="activeButtonSave" @click="Save">Guardar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ListaSegmento, ListaTag, ListaListas, SaveConfiguration } from './apiServices';
import { useWorkflowStore } from '../../../store/workflowStore';

export default{
    name: 'AudienciaPrefinida',
    props: {
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        descriptionOfSidebar: {
            type: String,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        audienceUpdate: null,
        typeFor: null,
        audienceSelected: null,
        audienceSelectedData: null,
        activeButtonSave: true,
        store: useWorkflowStore()
    }),
    watch: {
        async typeFor(data){
            this.disabledButtonSave()
            this.store.sidebarMessageNotSaved = true
            this.audienceSelectedData = null
            this.audienceSelected = null
            const functionList = {
                SEGMENT: this.ListaSegmentoData,
                TAG: this.ListaTagData,
                CONTACTGROUP: this.ListaListasData
            }
            if(functionList[data]){
                this.audienceSelectedData = await functionList[data]()
            }
        },
        dataForSidebarConfiguration: {
            handler(newVal){
                this.audienceUpdate = newVal.isRealTime
                this.typeFor = newVal.typeContact
                setTimeout(() => {
                    this.audienceSelected = newVal.contactId
                },300)
            },
            deep: true
        },
        audienceUpdate(){
            this.disabledButtonSave()
            this.store.sidebarMessageNotSaved = true

        },
        audienceSelected(){
            this.disabledButtonSave()
            this.store.sidebarMessageNotSaved = true
        }
    },
    mounted(){
        this.disabledButtonSave()
    },
    methods: {
        async ListaSegmentoData(){
            return await ListaSegmento()
        },
        async ListaTagData(){
            return await ListaTag()
        },
        async ListaListasData(){
            return await ListaListas()
        },
        async Save(){
            this.store.sidebarMessageNotSaved = false
            const params = {
                activatorId: this.idNodeType,
                nodeId: this.nodeId,
                isRealTime: this.audienceUpdate,
                typeContact: this.typeFor,
                contactId: this.audienceSelected
            }
            await SaveConfiguration(params)
        },
        disabledButtonSave(){
            if(this.audienceUpdate === null || this.typeFor === null || this.audienceSelected === null){
                this.activeButtonSave = true
            } else {
                this.activeButtonSave = false
            }
        }
    }
}
</script>