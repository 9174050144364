<template>
    <transition name="WorkflowNewActivatorFade">
        <div v-if="active" class="modalDelete" :class="{'customNode':customNodeType}">
            <p>¿Está seguro de elminar?</p>
            <div class="modalDelete-buttons">
                <button @click="closeModal">
                    Cancelar
                </button>
                <button @click="continueDelete">
                    Eliminar
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
export default{
    name: 'ModalDelete',
    props: {
        active: {
            type: Boolean,
            required: true
        },
        customNodeType: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close', 'delete'],
    data: () => ({
    }),
    methods: {
        closeModal(){
            this.$emit('close')
        },
        continueDelete(){
            this.$emit('delete')
        }
    }
}
</script>

<style scoped>
.modalDelete{
    position: fixed;
    background-color: #FFF;
    padding: 8px;
    border-radius: 12px;
    box-shadow: 0 0 8px rgba(0,0,0, .4);
}

.modalDelete.customNode{
    position: absolute;
    top: 0 !important;
    left: -190px !important;
}

.modalDelete::after{
    content: '';
    position: absolute;
    right: -10px;
    top: 6px;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    width: 12px;
    height: 16px;
    background-color: #FFF;
}

.modalDelete p{
    font-size: 14px;
    margin-bottom: 6px;
}

.modalDelete-buttons{
    display: flex;
    justify-content: center;
    gap: 8px;
}

.modalDelete-buttons button{
    border: none;
    outline: none;
    font-size: 12px;
    border-radius: 6px;
    transition: all .3s;
}

.modalDelete-buttons button:hover{
    box-shadow: 0 4px 4px rgba(0,0,0, .2);
}

.modalDelete-buttons button:nth-child(1){
    background-color: #cecece;
}

.modalDelete-buttons button:nth-child(2){
    background-color: #C55845;
    color: #FFF;
}

.modalDelete-buttons:hover{
    box-shadow: none;
}
</style>