import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaTipoEvento: 'configuration/sidebard/eue/dropdown/1/available',
    listaEstadoEjecucion: 'configuration/sidebard/eue/dropdown/2/available',
    listaOpciones: 'configuration/sidebard/eue/dropdown/3/available',
    listaDuranteLosUltimos: 'configuration/sidebard/eue/dropdown/4/available',
    save: 'action/configuration/sidebard/eue/add'
}

export async function ListaTipoEvento() {
    try {
        const response = await postRequest(apiUrls.listaTipoEvento, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaEstadoEjecucion() {
    try {
        const response = await postRequest(apiUrls.listaEstadoEjecucion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaOpciones() {
    try {
        const response = await postRequest(apiUrls.listaOpciones, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaDuranteLosUltimos() {
    try {
        const response = await postRequest(apiUrls.listaDuranteLosUltimos, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            actionId: params.actionId,
            nodeId: params.nodeId,
            conditions: params.conditions,
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "theme": "auto",
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}