<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="editableDiv-content">
    <div class="row">
        <div class="col-8">
            <p class="workflow-management-filters-label">Variables para el JSON</p>
            <select
                v-model="optionSelected"
                class="multisteps-form__select form-control form-select"
                :disabled="selectOptions.length === 0"
                @change="addOptionToEditableDiv"
            >
                <option v-for="(item, i) in selectOptions" :key="i" :value="item.headerName">{{ item.headerName }}</option>
            </select>
        </div>
        <div class="col-12 mt-3">
          <p class="workflow-management-filters-label">Payload JSON</p>
          <div
              ref="editableDiv"
              class="editableDiv"
              contenteditable="true"
              @click="saveCursorPosition"
              @keyup="saveCursorPosition"
          ></div>
          <button class="editableDiv-button" @click="sendContent">Procesar JSON</button>
        </div>
    </div>
  </div>
  </template>
  
  <script>  
  export default {
    name: 'DynamicInput',
    props: {
      selectOptions: {
        type: Array,
        default: () => []
      },
      contentEditableDiv: {
        type: Object,
        default: () => ({})
      }
    },
    emits: ['updateContent'],
    data: () => ({
        optionSelected: '',
        cursorPosition: null
    }),
    watch: {
      contentEditableDiv: {
        handler(newVal) {
          const editableDiv = this.$refs.editableDiv;
          editableDiv.innerHTML = JSON.stringify(newVal, null, 2).replace(/\n/g, '<br>').replace(/\s/g, '&nbsp;');
        },
        deep: true
      }
    },
    methods: {
      saveCursorPosition() {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          this.cursorPosition = selection.getRangeAt(0);
        }
      },
      addOptionToEditableDiv() {
        const wordToAdd = `<p class="editableDiv-json-highlight">{ ${this.optionSelected} }</p>`;
        
        if (this.cursorPosition) {
          const range = this.cursorPosition;
          range.deleteContents(); // Borra el contenido seleccionado, si lo hay
  
          // Inserta el nodo HTML con la palabra resaltada
          const fragment = document.createRange().createContextualFragment(wordToAdd);
          const firstNode = fragment.firstChild; // Nodo inicial del fragmento
          const lastNode = fragment.lastChild; // Nodo final del fragmento
  
          range.insertNode(fragment); // Inserta el fragmento
  
          // Inserta un nodo vacío después del último nodo para romper el estilo
          const spaceNode = document.createTextNode(" "); // Nodo de texto vacío
          range.setStartAfter(lastNode || firstNode); // Asegura que sea un nodo válido
          range.insertNode(spaceNode);
  
          // Restaura el cursor después del espacio
          const selection = window.getSelection();
          selection.removeAllRanges();
          range.setStartAfter(spaceNode); // Posiciona el cursor después del espacio
          range.collapse(true);
          selection.addRange(range);
        }
      },
      sendContent() {
        const editableDiv = this.$refs.editableDiv;

        // Verifica si el contenido editable está presente
        if (editableDiv) {
          // Obtén el texto sin HTML y elimina los espacios en blanco
          const cleanText = editableDiv.innerText.replace(/\s+/g, "")
          try {
            JSON.parse(cleanText);
          } catch (e) {
            alert("El contenido no es un JSON válido");
            return;
          }
          this.$emit('updateContent', JSON.parse(cleanText));
        }
      }
    },
  };
  </script>
  
  <style lang="scss">
  .editableDiv {
    width: 100%;
    min-height: 80px;
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px 16px;
    white-space: pre-wrap;
    word-wrap: break-word;
    outline: none;
    font-weight: 600;
    background-color: #fff;

    &-content{
      padding: 16px;
      border-radius: 12px;
      background-color: #f3f3f3;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar {
        width: 12px;
        height: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: #ececec;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        border: 3px solid #ececec;
    }

    &-button{
      background-color: #118555;
      color: #fff;
      border: none;
      outline: none;
      border-radius: 6px;
      padding: 4px 8px;
      cursor: pointer;
      font-size: 12px;
      margin-top: 16px;
      transition: all .3s;

      &:hover {
        background-color: #0e6e4f;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      }
    }

    &-json-highlight {
      display: inline-block;
      background-color: #118555;
      padding: 2px 8px 4px 8px;
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      margin-top: -2px;
      transform: translateY(-2px);
      margin-bottom: 8px;
    }
  }
  </style>