<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
    <div class="container-fluid py-3 MensajeInbound-container">
        <div class="row">
            <div class="col-8">
                <p class="workflow-management-filters-label">Remitente</p>
                <select
                    v-model="params.recipientId"
                    class="multisteps-form__select form-control form-select"
                >
                    <option v-for="(item, i) in recipientIdList" :key="i" :value="item.senderMsisdnId">{{ item.msisdn }}</option>
                </select>
            </div>
            <div class="col-12 mt-3">
                <div class="workflow-panel-menu-options-radio-buttons">
                    <label class="workflow-input-radio">
                        <input v-model="params.messageType" type="radio" :value="1">
                        <span class="checkmark"></span>
                        <p>Mensaje</p>
                    </label>
                    <label class="workflow-input-radio">
                        <input v-model="params.messageType" type="radio" :value="2">
                        <span class="checkmark"></span>
                        <p>Template</p>
                    </label>
                </div>
            </div>
            <div v-if="params.messageType === 1" class="col-12 mt-3">
                <p class="workflow-management-filters-label">Mensaje</p>
                <textarea v-model="params.text" class="form-control" style="min-height: 100px"></textarea>
            </div>
            <div v-if="params.messageType === 2" class="col-8 mt-3">
                <p class="workflow-management-filters-label">Template</p>
                <select
                    v-model="params.templateId"
                    class="multisteps-form__select form-control form-select"
                >
                    <option v-for="(item, i) in templateIdList" :key="i" :value="item.draftFlexId">{{ item.name }}</option>
                </select>
            </div>
        </div>
        <h6 class="mt-3">Agendamiento del Mensaje</h6>
        <div class="row mt-3">
            <div class="col-6">
                <p class="workflow-management-filters-label">Hora de Inicio</p>
                <WorkflowTimePicker @changeTime="changeTimeStartTime" :initialData="params.startTime"/>
            </div>
            <div class="col-6">
                <p class="workflow-management-filters-label">Hora de Fin</p>
                <WorkflowTimePicker @changeTime="changeTimeEndTime" :initialData="params.endTime"/>
            </div>
        </div>
        <p class="input-checkbox-title">Días de la semana</p>
        <div class="row mt-3">
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="monday">Lunes</label>
                    <input type="checkbox" id="monday" :checked="params.daysOfWeek.monday" @change="params.daysOfWeek.monday = !params.daysOfWeek.monday"/>
                </div>
            </div>
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="tuesday">Martes</label>
                    <input type="checkbox" id="tuesday" :checked="params.daysOfWeek.tuesday" @change="params.daysOfWeek.tuesday = !params.daysOfWeek.tuesday"/>
                </div>
            </div>
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="wednesday">Miércoles</label>
                    <input type="checkbox" id="wednesday" :checked="params.daysOfWeek.wednesday" @change="params.daysOfWeek.wednesday = !params.daysOfWeek.wednesday"/>
                </div>
            </div>
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="thursday">Jueves</label>
                    <input type="checkbox" id="thursday" :checked="params.daysOfWeek.thursday" @change="params.daysOfWeek.thursday = !params.daysOfWeek.thursday"/>
                </div>
            </div>
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="friday">Viernes</label>
                    <input type="checkbox" id="friday" :checked="params.daysOfWeek.friday" @change="params.daysOfWeek.friday = !params.daysOfWeek.friday"/>
                </div>
            </div>
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="saturday">Sábado</label>
                    <input type="checkbox" id="saturday" :checked="params.daysOfWeek.saturday" @change="params.daysOfWeek.saturday = !params.daysOfWeek.saturday"/>
                </div>
            </div>
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="sunday">Domingo</label>
                    <input type="checkbox" id="sunday" :checked="params.daysOfWeek.sunday" @change="params.daysOfWeek.sunday = !params.daysOfWeek.sunday"/>
                </div>
            </div>
        </div>
        <p class="input-checkbox-title">Rastreo Link</p>
        <div class="row mt-3">
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="clickPerContact">Click por contacto</label>
                    <input type="checkbox" id="clickPerContact" :checked="params.clickPerContact" @change="params.clickPerContact = !params.clickPerContact"/>
                </div>
            </div>
            <div class="col-6">
                <div class="input-checkbox">
                    <label for="isShortUrl">Acortar URL</label>
                    <input type="checkbox" id="isShortUrl" :checked="params.isShortUrl" @change="params.isShortUrl = !params.isShortUrl"/>
                </div>
            </div>
        </div>
        <p class="input-checkbox-title">Tasa de Envío</p>
        <div class="row mt-3">
            <div class="col-6">
                <p class="workflow-management-filters-label">Número de Mensajes</p>
                <input type="number" v-model="params.messageInterval" class="form-control"/>
            </div>
            <div class="col-6">
                <p class="workflow-management-filters-label">Unidad de Tiempo</p>
                <select
                    v-model="params.timeUnit"
                    class="multisteps-form__select form-control form-select"
                >
                    <option value="minuto">Minuto</option>
                    <option value="hora">Hora</option>
                    <option value="dia">Dia</option>
                </select>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <div class="input-checkbox">
                    <label for="doNotContact">Ignorar lista no contactar</label>
                    <input type="checkbox" id="doNotContact" :checked="params.doNotContact" @change="params.doNotContact = !params.doNotContact"/>
                </div>
            </div>
        </div>
        <div class="row justify-content-end mt-4">
            <div class="col-auto">
                <button type="button" class="btn btn-primary" :disabled="false" @click="Save">Guardar</button>
            </div>
        </div>
    </div>
</template>

<script>
import WorkflowTimePicker from '../../../WorkflowTimePicker.vue';
import { ListaSenders, ListaTemplates, SaveConfiguration } from './apiServices';

export default {
    name: 'EnviarSms',
    components: {
        WorkflowTimePicker,
    },
    props: {
        nodeId: {
            type: String,
            required: true
        },
        idNodeType: {
            type: Number,
            required: true
        },
        actionType: {
            type: String,
            required: true
        },
        dataForSidebarConfiguration: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        params: {
            recipientId: '',
            messageType: 1,
            text: '',
            templateId: '',
            startTime: '',
            endTime: '',
            daysOfWeek: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            },
            isShortUrl: false,
            clickPerContact: false,
            messageInterval: 0,
            timeUnit: '',
            doNotContact: false
        },
        recipientIdList: [],
        templateIdList: [],
        activeButtonSave: true
    }),
    watch: {
        'params.messageType': async function (val) {
            if (val === 2) {
                this.params.text = '';
                this.templateIdList = await ListaTemplates()
            } else {
                this.params.templateId = '';
            }
        },
        dataForSidebarConfiguration: {
            handler(newVal) {
                if (Object.keys(newVal).length !== 0) {
                    Object.keys(this.params).forEach(key => {
                        if (Object.prototype.hasOwnProperty.call(newVal, key) && !['nodeId', 'actionId', 'actionType'].includes(key)) {
                            this.params[key] = newVal[key];
                        }
                    });
                }
            },
            deep: true
        }
    },
    async mounted() {
        this.recipientIdList = await ListaSenders();
    },
    methods: {
        changeTimeStartTime(time) {
            this.params.startTime = time;
        },
        changeTimeEndTime(time) {
            this.params.endTime = time;
        },
        async Save() {
            const params = {
                ...this.params,
                nodeId: this.nodeId,
                actionId: this.idNodeType,
                actionType: this.actionType
            };
            await SaveConfiguration(params);
        }
    }
};
</script>