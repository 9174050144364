import { postRequest } from "../../../services/axiosConfig";
import { toast } from 'vue3-toastify';

const apiUrls = {
    listaSenders: 'configuration/sidebard/sender/available',
    listaCondicion: 'configuration/sidebard/me/dropdown/1/available',
    listaSeleccion: 'configuration/sidebard/me/dropdown/2/available',
    save: 'configuration/sidebard/me/add'
}

export async function ListaSenders(params) {
    const requestData = {
        request: JSON.stringify({
            channelId: params.channelId,
        })
    };
    try {
        const response = await postRequest(apiUrls.listaSenders, requestData);
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaCondicion() {
    try {
        const response = await postRequest(apiUrls.listaCondicion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function ListaSeleccion() {
    try {
        const response = await postRequest(apiUrls.listaSeleccion, {});
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}

export async function SaveConfiguration(params) {
    const requestData = {
        request: JSON.stringify({
            activatorId: params.activatorId,
            nodeId: params.nodeId,
            channel: params.channel,
            conditions: params.conditions,
        })
    };
    try {
        const response = await postRequest(apiUrls.save, requestData);
        toast("La configuración ha sido creada con éxto.", {
            "theme": "auto",
            "type": "success",
            "position": "bottom-right",
            "autoClose": 5000
        })
        return response.data.data;
    } catch (error) {
        console.error(error.message);
    }
}