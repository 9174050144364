<!-- eslint-disable vue/attributes-order -->
<template>
    <div class="container-fluid py-3">
        <div class="row">
            <div class="col-12">
                <p class="font-14 mb-2">API Celmon</p>
                <a href="#" target="_blank" class="text-primary">https://getbootstrap.com/docs/5.0/content/reboot/#inline-code</a>
            </div>
            <div class="col-12 mt-4">
                <p class="font-14 mb-2">Ejemplo de llamado a la API</p>
                <pre><code class="language-javascript">{{ codeSnippet }}</code></pre>
            </div>
        </div>
    </div>
</template>

<script>
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript';

export default{
    name: 'Api',
    data: () => ({
        codeSnippet: `this.forms.push({
            eventType: null,
            executionState: null,
            conditionsArray: [
                {
                    typeOfCondition: null,
                    conditionalOne: null,
                    countConditionalOne: 0,
                    conditionalTwo: null,
                    countConditionalTwo: 0
                }
            ]
        })`,
    }),
    mounted() {
    Prism.highlightAll();
  }
}
</script>